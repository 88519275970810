import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import ProjectList from '../project/ProjectList';

export default function Projects() {
  const { orgId } = useParams();
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          setOrganizationName(orgDoc.data().name);
        }
      } catch (error) {
        console.error('Hiba:', error);
      }
    };

    fetchOrganization();
  }, [orgId]);

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-4 sm:py-8">
      <nav className="mb-4 sm:mb-8 bg-white shadow-sm rounded-lg px-3 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-1 sm:space-x-2 text-[7pt] sm:text-[8pt]">
          <li className="flex items-center">
            <Link 
              to="/" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out flex items-center"
            >
              <svg 
                className="h-3 w-3 mr-1" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to="/organizations" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              Szervezetek
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to={`/organization/${orgId}`}
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              {organizationName || 'Betöltés...'}
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">
            Projektek
          </li>
        </ol>
      </nav>

      <div className="mb-3 sm:mb-6">
        <h2 className="text-lg sm:text-xl font-bold leading-7 text-gray-900 lg:text-2xl">
          Projektek kezelése
        </h2>
      </div>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <ProjectList organizationId={orgId} />
      </div>
    </div>
  );
} 
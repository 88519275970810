import React, { useEffect } from 'react';

export default function Snowfall() {
  useEffect(() => {
    const canvas = document.getElementById('snowfall');
    const ctx = canvas.getContext('2d');
    
    const snowflakes = Array.from({ length: 100 }, () => ({
      x: Math.random() * window.innerWidth,
      y: Math.random() * 64,
      radius: Math.random() * 3 + 1,
      speed: Math.random() * 1 + 0.5,
      opacity: Math.random() * 0.7 + 0.3
    }));

    const updateSnowflakes = () => {
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      
      snowflakes.forEach(snowflake => {
        snowflake.y += snowflake.speed;
        snowflake.x += Math.sin(snowflake.y * 0.01) * 0.5;

        if (snowflake.y > canvas.height) {
          snowflake.y = -5;
          snowflake.x = Math.random() * canvas.width;
        }

        ctx.beginPath();
        ctx.arc(snowflake.x, snowflake.y, snowflake.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(255, 255, 255, ${snowflake.opacity})`;
        ctx.fill();
      });

      requestAnimationFrame(updateSnowflakes);
    };

    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = 64;
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    updateSnowflakes();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <canvas 
      id="snowfall" 
      className="absolute top-0 left-0 w-full h-16 pointer-events-none"
      style={{ 
        zIndex: 10,
        opacity: 0.8
      }} 
    />
  );
} 
export default function WebForYouLogo() {
  return (
    <div className="fixed bottom-4 right-4 z-50">
      <div className="bg-black p-2 rounded-lg">
        <a 
          href="https://webforyou.hu" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <img 
            src="/webforyou.svg" 
            alt="WebForYou" 
            className="h-6 w-auto"
          />
        </a>
      </div>
    </div>
  );
} 
import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc, serverTimestamp, arrayUnion, deleteDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import LoadingScreen from '../ui/LoadingScreen';
import { createNotification } from '../../utils/notifications';

// Dátum formázó függvény
function formatDate(timestamp) {
  if (!timestamp) return 'Nincs dátum';
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  
  try {
    // Handle both Firestore Timestamp and regular date strings
    const date = timestamp.seconds ? 
      new Date(timestamp.seconds * 1000) : 
      new Date(timestamp);
      
    // Check if date is valid before formatting
    if (isNaN(date.getTime())) {
      return 'Érvénytelen dátum';
    }
    
    return date.toLocaleDateString('hu-HU', options);
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return 'Érvénytelen dátum';
  }
}

function formatWorkTime(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  return `${hours} óra ${mins} perc`;
}

// Add this function near other helper functions
function getActionText(action) {
  switch (action) {
    case 'created': return 'Létrehozva';
    case 'status_changed': return 'Státusz módosítva';
    case 'worktime_added': return 'Munkaidő hozzáadva';
    case 'worktime_edited': return 'Munkaidő módosítva';
    case 'worktime_deleted': return 'Munkaidő törölve';
    case 'comment_added': return 'Hozzászólás hozzáadva';
    case 'comment_edited': return 'Hozzászólás módosítva';
    case 'comment_deleted': return 'Hozzászólás törölve';
    case 'edited': return 'Szerkesztve';
    case 'ticket_edited': return 'Ticket adatok módosítva';
    case 'visit_added': return 'Kiszállás hozzáadva';
    default: return action;
  }
}

// Add these helper functions
function getStatusButtonStyle(status) {
  switch (status) {
    case 'new':
      return 'bg-blue-50 text-blue-700 border-blue-300 hover:bg-blue-100';
    case 'in_progress':
      return 'bg-yellow-50 text-yellow-700 border-yellow-300 hover:bg-yellow-100';
    case 'waiting_for_client':
      return 'bg-purple-50 text-purple-700 border-purple-300 hover:bg-purple-100';
    case 'waiting_for_third_party':
      return 'bg-orange-50 text-orange-700 border-orange-300 hover:bg-orange-100';
    case 'collector':
      return 'bg-pink-50 text-pink-700 border-pink-300 hover:bg-pink-100';
    case 'resolved':
      return 'bg-green-50 text-green-700 border-green-300 hover:bg-green-100';
    case 'closed':
      return 'bg-gray-50 text-gray-700 border-gray-300 hover:bg-gray-100';
    default:
      return 'bg-gray-50 text-gray-700 border-gray-300 hover:bg-gray-100';
  }
}

function getStatusText(status) {
  switch (status) {
    case 'new': return 'Új';
    case 'in_progress': return 'Folyamatban';
    case 'waiting_for_client': return 'Ügyfél válaszára vár';
    case 'waiting_for_third_party': return 'Harmadik fél válaszára vár';
    case 'collector': return 'Gyűjtő ticket';
    case 'resolved': return 'Megoldva';
    case 'closed': return 'Lezárva';
    default: return status;
  }
}

function getStatusBadgeColor(status) {
  switch (status) {
    case 'new':
      return 'bg-blue-100 text-blue-800';
    case 'in_progress':
      return 'bg-yellow-100 text-yellow-800';
    case 'waiting_for_client':
      return 'bg-purple-100 text-purple-800';
    case 'waiting_for_third_party':
      return 'bg-orange-100 text-orange-800';
    case 'collector':
      return 'bg-pink-100 text-pink-800';
    case 'resolved':
      return 'bg-green-100 text-green-800';
    case 'closed':
      return 'bg-gray-100 text-gray-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

function getPriorityBadgeColor(priority) {
  switch (priority) {
    case 'low':
      return 'bg-green-100 text-green-800';
    case 'medium':
      return 'bg-yellow-100 text-yellow-800';
    case 'high':
      return 'bg-red-100 text-red-800';
    default:
      return 'bg-gray-100 text-gray-800';
  }
}

function getPriorityText(priority) {
  switch (priority) {
    case 'low': return 'Alacsony';
    case 'medium': return 'Közepes';
    case 'high': return 'Magas';
    default: return priority;
  }
}

// Új függvény az org felhasználóinak lekérdezéséhez
const sendNotificationToOrgUsers = async (notificationData, currentUser) => {
  try {
    const orgDoc = await getDoc(doc(db, 'organizations', notificationData.orgId));
    if (!orgDoc.exists()) return;

    const orgData = orgDoc.data();
    const orgUsers = orgData.users || [];

    const notifications = orgUsers
      .filter(user => user.id !== currentUser.uid)
      .map(user => createNotification({
        userId: user.id,
        ...notificationData
      }));

    await Promise.all(notifications);
  } catch (error) {
    console.error('Error sending notifications:', error);
  }
};

export default function TicketView() {
  const { orgId, ticketId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [ticket, setTicket] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [updating, setUpdating] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editForm, setEditForm] = useState({
    title: '',
    description: '',
    status: '',
    priority: '',
    clientId: '',
    contact: null,
    deadline: ''
  });
  const [comment, setComment] = useState('');
  const [isSubmittingComment, setIsSubmittingComment] = useState(false);
  const [workTime, setWorkTime] = useState({
    description: '',
    hours: '',
    minutes: '',
    date: new Date().toISOString().split('T')[0], // Mai dátum alapértelmezetten
    isSubmitting: false
  });
  const [editingWorkTime, setEditingWorkTime] = useState(null);
  const [deletingWorkTime, setDeletingWorkTime] = useState(null);
  const [editingComment, setEditingComment] = useState(null);
  const [deletingComment, setDeletingComment] = useState(null);
  const [visit, setVisit] = useState({
    description: '',
    date: '',
    isSubmitting: false
  });
  const [editingVisit, setEditingVisit] = useState(null);
  const [deletingVisit, setDeletingVisit] = useState(null);
  const [clients, setClients] = useState([]);
  const [isDeletingTicket, setIsDeletingTicket] = useState(false);
  
  // Referencia a dropdown menühöz
  const menuRef = useRef(null);

  // Kattintás figyelése a dokumentumon a menü bezárásához
  useEffect(() => {
    function handleClickOutside(event) {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    async function fetchTicket() {
      try {
        const ticketDoc = await getDoc(doc(db, 'tickets', ticketId));
        if (ticketDoc.exists()) {
          const data = ticketDoc.data();
          // Konvertáljuk a timestamp-et Date objektummá
          const createdAt = data.createdAt?.seconds ? 
            new Date(data.createdAt.seconds * 1000) : 
            new Date(data.createdAt);
            
          const updatedAt = data.updatedAt?.seconds ? 
            new Date(data.updatedAt.seconds * 1000) : 
            data.updatedAt ? new Date(data.updatedAt) : null;

          setTicket({
            id: ticketDoc.id,
            ...data,
            createdAt,
            updatedAt
          });
        } else {
          setError('Ticket nem található');
        }
      } catch (err) {
        setError('Hiba történt az adatok betöltésekor');
        console.error(err);
      } finally {
        setLoading(false);
      }
    }

    fetchTicket();
  }, [ticketId]);

  // Form inicializálása amikor betöltődik a ticket
  useEffect(() => {
    if (ticket) {
      setEditForm({
        title: ticket.title || '',
        description: ticket.description || '',
        status: ticket.status || '',
        priority: ticket.priority || '',
        clientId: ticket.clientId || '',
        contact: ticket.contact || null,
        deadline: ticket.deadline ? new Date(ticket.deadline).toISOString().split('T')[0] : ''
      });
    }
  }, [ticket]);

  useEffect(() => {
    async function fetchClients() {
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          setClients(orgDoc.data().clients || []);
        }
      } catch (err) {
        console.error('Hiba az ügyfelek betöltésekor:', err);
      }
    }

    fetchClients();
  }, [orgId]);

  // Státusz változásnál
  const handleStatusChange = async (newStatus) => {
    if (!ticket || updating) return;
    
    setIsMenuOpen(false);
    setUpdating(true);
    
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      const historyEntry = {
        action: 'status_changed',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Státusz módosítva: ${getStatusText(ticket.status)} → ${getStatusText(newStatus)}`
      };

      // Firebase update
      await updateDoc(ticketRef, {
        status: newStatus,
        updatedAt: serverTimestamp(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        },
        history: arrayUnion(historyEntry)
      });

      // Értesítés küldése az összes org felhasználónak
      await sendNotificationToOrgUsers({
        title: 'Status changed',
        message: `${currentUser.displayName || currentUser.email} módosította a "${ticket.title}" ticket státuszát: ${getStatusText(ticket.status)} → ${getStatusText(newStatus)}`,
        type: 'status_changed',
        link: `/organization/${orgId}/tickets/${ticketId}`,
        metadata: {
          ticketId,
          oldStatus: ticket.status,
          newStatus: newStatus
        }
      }, currentUser);

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        status: newStatus,
        updatedAt: now.toISOString(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        },
        history: [...(prev.history || []), historyEntry]
      }));

    } catch (err) {
      console.error('Státusz módosítási hiba:', err);
      setError('Hiba történt a státusz módosításakor: ' + err.message);
    } finally {
      setUpdating(false);
    }
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    if (!editForm.title.trim()) return;

    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      // History bejegyzés
      const historyEntry = {
        action: 'ticket_edited',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Ticket adatok módosítva'
      };

      await updateDoc(ticketRef, {
        ...editForm,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        ...editForm,
        history: [...(prev.history || []), historyEntry],
        updatedAt: now.toISOString(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      }));

      setIsEditing(false);
    } catch (err) {
      console.error('Szerkesztési hiba:', err);
      setError('Hiba történt a mentés során: ' + err.message);
    }
  };

  const handleAddComment = async (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    setIsSubmittingComment(true);
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      const commentData = {
        id: crypto.randomUUID(),
        content: comment.trim(),
        createdAt: now.toISOString(),
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      const historyEntry = {
        action: 'comment_added',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Új hozzászólás'
      };

      await updateDoc(ticketRef, {
        comments: arrayUnion(commentData),
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Frissítjük a lokális state-et
      setTicket(prev => ({
        ...prev,
        comments: [...(prev.comments || []), commentData],
        history: [...(prev.history || []), historyEntry]
      }));

      setComment(''); // Form törlése

      // Értesítés küldése az összes org felhasználónak
      await sendNotificationToOrgUsers({
        orgId, // Ez a paraméter a useParams()-ból jön
        title: 'Új hozzászólás',
        message: `${currentUser.displayName || currentUser.email} hozzászólt a "${ticket.title}" tickethez`,
        type: 'comment_added',
        link: `/organization/${orgId}/tickets/${ticketId}`,
        metadata: {
          ticketId,
          commentId: commentData.id
        }
      }, currentUser);

      if (ticket.createdBy?.id && ticket.createdBy.id !== currentUser.uid) {
        console.log('Sending notification to creator:', ticket.createdBy.id);
        await createNotification({
          userId: ticket.createdBy.id,
          title: 'Új hozzászólás',
          message: `${currentUser.displayName || currentUser.email} hozzászólt a "${ticket.title}" tickethez`,
          type: 'comment_added',
          link: `/organization/${orgId}/tickets/${ticketId}`,
          metadata: {
            ticketId,
            commentId: commentData.id
          }
        });
      }

      if (ticket.assignedTo?.id && ticket.assignedTo.id !== currentUser.uid && ticket.assignedTo.id !== ticket.createdBy.id) {
        await createNotification({
          userId: ticket.assignedTo.id,
          title: 'Új hozzászólás',
          message: `${currentUser.displayName || currentUser.email} hozzászólt a "${ticket.title}" tickethez`,
          type: 'comment_added',
          link: `/organization/${orgId}/tickets/${ticketId}`,
          metadata: {
            ticketId,
            commentId: commentData.id
          }
        });
      }
    } catch (err) {
      console.error('Komment hozzáadási hiba:', err);
      setError('Hiba történt a komment hozzáadásakor: ' + err.message);
    } finally {
      setIsSubmittingComment(false);
    }
  };

  const handleAddWorkTime = async (e) => {
    e.preventDefault();
    if (!workTime.description.trim() || (workTime.hours === '' && workTime.minutes === '') || !workTime.date) return;

    setWorkTime(prev => ({ ...prev, isSubmitting: true }));
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      const totalMinutes = (parseInt(workTime.hours || 0) * 60) + parseInt(workTime.minutes || 0);
      
      if (totalMinutes === 0) {
        throw new Error('Kérjük adjon meg munkaidőt');
      }

      const workTimeEntry = {
        description: workTime.description.trim(),
        minutes: totalMinutes,
        date: workTime.date, // Mentjük a kiválasztott dátumot
        createdAt: now.toISOString(),
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      const historyEntry = {
        action: 'worktime_added',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Munkaidő hozzáadva: ${formatWorkTime(totalMinutes)}`
      };

      await updateDoc(ticketRef, {
        workTimes: arrayUnion(workTimeEntry),
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Frissítjük a lokális state-et
      setTicket(prev => ({
        ...prev,
        workTimes: [...(prev.workTimes || []), workTimeEntry],
        history: [...(prev.history || []), historyEntry]
      }));

      // Form törlése
      setWorkTime({
        description: '',
        hours: '',
        minutes: '',
        date: new Date().toISOString().split('T')[0], // Reset to today
        isSubmitting: false
      });

      // Értesítés küldése a ticket tulajdonosának és az assignee-nek
      await sendNotificationToOrgUsers({
        title: 'Új munkaidő rögzítve',
        message: `${currentUser.displayName || currentUser.email} munkaidőt rögzített a "${ticket.title}" tickethez`,
        type: 'worktime_added',
        link: `/organization/${orgId}/tickets/${ticketId}`,
        metadata: {
          ticketId,
          workTimeMinutes: totalMinutes
        }
      }, currentUser);

      if (ticket.createdBy?.id && ticket.createdBy.id !== currentUser.uid) {
        await createNotification({
          userId: ticket.createdBy.id,
          title: 'Új munkaidő rögzítve',
          message: `${currentUser.displayName || currentUser.email} munkaidőt rögzített a "${ticket.title}" tickethez`,
          type: 'worktime_added',
          link: `/organization/${orgId}/tickets/${ticketId}`,
          metadata: {
            ticketId,
            workTimeMinutes: totalMinutes
          }
        });
      }

      if (ticket.assignedTo?.id && ticket.assignedTo.id !== currentUser.uid && ticket.assignedTo.id !== ticket.createdBy.id) {
        await createNotification({
          userId: ticket.assignedTo.id,
          title: 'Új munkaidő rögzítve',
          message: `${currentUser.displayName || currentUser.email} munkaidőt rögzített a "${ticket.title}" tickethez`,
          type: 'worktime_added',
          link: `/organization/${orgId}/tickets/${ticketId}`,
          metadata: {
            ticketId,
            workTimeMinutes: totalMinutes
          }
        });
      }
    } catch (err) {
      console.error('Munkaidő hozzáadási hiba:', err);
      setError('Hiba történt a munkaidő hozzáadásakor: ' + err.message);
    } finally {
      setWorkTime(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  const handleEditWorkTime = async (e, index) => {
    e.preventDefault();
    if (!editingWorkTime) return;

    setWorkTime(prev => ({ ...prev, isSubmitting: true }));
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      const totalMinutes = (parseInt(editingWorkTime.hours || 0) * 60) + parseInt(editingWorkTime.minutes || 0);
      
      if (totalMinutes === 0) {
        throw new Error('Kérjük adjon meg munkaidőt');
      }

      const updatedWorkTimeEntry = {
        description: editingWorkTime.description.trim(),
        minutes: totalMinutes,
        date: editingWorkTime.date, // Mentjük a módosított dátumot
        createdAt: ticket.workTimes[index].createdAt,
        updatedAt: now.toISOString(),
        createdBy: ticket.workTimes[index].createdBy,
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      // History bejegyzés
      const historyEntry = {
        action: 'worktime_edited',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Munkaidő módosítva: ${formatWorkTime(totalMinutes)}`
      };

      // Munkaidő lista frissítése
      const updatedWorkTimes = [...ticket.workTimes];
      updatedWorkTimes[index] = updatedWorkTimeEntry;

      await updateDoc(ticketRef, {
        workTimes: updatedWorkTimes,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        workTimes: updatedWorkTimes,
        history: [...(prev.history || []), historyEntry]
      }));

      setEditingWorkTime(null); // Szerkesztés befejezése
    } catch (err) {
      console.error('Munkaidő módosítási hiba:', err);
      setError('Hiba történt a munkaidő módosításakor: ' + err.message);
    } finally {
      setWorkTime(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  const startEditing = (workTimeEntry, index) => {
    const hours = Math.floor(workTimeEntry.minutes / 60);
    const minutes = workTimeEntry.minutes % 60;
    setEditingWorkTime({
      index,
      description: workTimeEntry.description,
      hours: hours.toString(),
      minutes: minutes.toString(),
      date: workTimeEntry.date || new Date().toISOString().split('T')[0] // Használjuk a meglévő dátumot vagy mai dátumot
    });
  };

  const handleDeleteWorkTime = async (index) => {
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      // Az eredeti munkaidő bejegyzés
      const workTimeToDelete = ticket.workTimes[index];

      // History bejegyzés
      const historyEntry = {
        action: 'worktime_deleted',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Munkaidő törölve: ${formatWorkTime(workTimeToDelete.minutes)} - ${workTimeToDelete.description}`
      };

      // Munkaidő lista frissítése a törölt elem nélkül
      const updatedWorkTimes = ticket.workTimes.filter((_, i) => i !== index);

      await updateDoc(ticketRef, {
        workTimes: updatedWorkTimes,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        workTimes: updatedWorkTimes,
        history: [...(prev.history || []), historyEntry]
      }));

      setDeletingWorkTime(null); // Törlés modal bezárása
    } catch (err) {
      console.error('Munkaidő törlési hiba:', err);
      setError('Hiba történt a munkaidő törlésekor: ' + err.message);
    }
  };

  const handleEditComment = async (e, index) => {
    e.preventDefault();
    if (!editingComment?.content.trim()) return;

    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      // Új komment adatok
      const updatedCommentEntry = {
        content: editingComment.content.trim(),
        createdAt: ticket.comments[index].createdAt, // Eredeti létrehozási idő
        createdBy: ticket.comments[index].createdBy, // Eredeti létrehozó
        updatedAt: now.toISOString(),
        updatedBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      // History bejegyzés
      const historyEntry = {
        action: 'comment_edited',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Hozzászólás módosítva'
      };

      // Komment lista frissítése
      const updatedComments = [...ticket.comments];
      updatedComments[index] = updatedCommentEntry;

      await updateDoc(ticketRef, {
        comments: updatedComments,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        comments: updatedComments,
        history: [...(prev.history || []), historyEntry]
      }));

      setEditingComment(null);
    } catch (err) {
      console.error('Hozzászólás módosítási hiba:', err);
      setError('Hiba történt a hozzászólás módosításakor: ' + err.message);
    }
  };

  const handleDeleteComment = async (index) => {
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      const commentToDelete = ticket.comments[index];

      // History bejegyzés
      const historyEntry = {
        action: 'comment_deleted',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: 'Hozzászólás törölve'
      };

      // Komment lista frissítése a törölt elem nélkül
      const updatedComments = ticket.comments.filter((_, i) => i !== index);

      await updateDoc(ticketRef, {
        comments: updatedComments,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        comments: updatedComments,
        history: [...(prev.history || []), historyEntry]
      }));

      setDeletingComment(null);
    } catch (err) {
      console.error('Hozzászólás törlési hiba:', err);
      setError('Hiba történt a hozzászólás törlésekor: ' + err.message);
    }
  };

  const handleAddVisit = async (e) => {
    e.preventDefault();
    if (!visit.description.trim() || !visit.date) return;

    setVisit(prev => ({ ...prev, isSubmitting: true }));
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);

      const visitEntry = {
        description: visit.description.trim(),
        date: visit.date,
        createdAt: now.toISOString(),
        createdBy: {
          id: currentUser.uid,
          email: currentUser.email,
          displayName: currentUser.displayName || '',
          name: currentUser.name || ''
        }
      };

      const historyEntry = {
        action: 'visit_added',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Kiszállás rögzítve: ${format(new Date(visit.date), 'yyyy.MM.dd')}`
      };

      await updateDoc(ticketRef, {
        visits: arrayUnion(visitEntry),
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      setTicket(prev => ({
        ...prev,
        visits: [...(prev.visits || []), visitEntry],
        history: [...(prev.history || []), historyEntry]
      }));

      setVisit({
        description: '',
        date: '',
        isSubmitting: false
      });

      // Értesítés küldése a ticket tulajdonosának és az assignee-nek
      if (ticket.createdBy?.id && ticket.createdBy.id !== currentUser.uid) {
        await createNotification({
          userId: ticket.createdBy.id,
          title: 'Új kiszállás rögzítve',
          message: `${currentUser.displayName || currentUser.email} kiszállást rögzített a "${ticket.title}" tickethez`,
          type: 'visit_added',
          link: `/organization/${orgId}/tickets/${ticketId}`,
          metadata: {
            ticketId,
            visitDate: visit.date
          }
        });
      }

      if (ticket.assignedTo?.id && ticket.assignedTo.id !== currentUser.uid && ticket.assignedTo.id !== ticket.createdBy.id) {
        await createNotification({
          userId: ticket.assignedTo.id,
          title: 'Új kiszállás rögzítve',
          message: `${currentUser.displayName || currentUser.email} kiszállást rögzített a "${ticket.title}" tickethez`,
          type: 'visit_added',
          link: `/organization/${orgId}/tickets/${ticketId}`,
          metadata: {
            ticketId,
            visitDate: visit.date
          }
        });
      }
    } catch (err) {
      console.error('Kiszállás rögzítési hiba:', err);
      setError('Hiba történt a kiszállás rögzítésekor: ' + err.message);
    } finally {
      setVisit(prev => ({ ...prev, isSubmitting: false }));
    }
  };

  // Összes munkaidő számítása
  const totalWorkTime = ticket?.workTimes?.reduce((total, wt) => total + wt.minutes, 0) || 0;

  const handleEditFormChange = (e) => {
    const { name, value } = e.target;
    setEditForm(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDeleteVisit = async (index) => {
    try {
      const now = new Date();
      const ticketRef = doc(db, 'tickets', ticketId);
      
      // Az eredeti kiszállás bejegyzés
      const visitToDelete = ticket.visits[index];

      // History bejegyzés
      const historyEntry = {
        action: 'visit_deleted',
        timestamp: now.toISOString(),
        userId: currentUser.uid,
        userDisplayName: currentUser.displayName || currentUser.name || currentUser.email,
        details: `Kiszállás törölve: ${format(new Date(visitToDelete.date), 'yyyy.MM.dd')} - ${visitToDelete.description}`
      };

      // Kiszállás lista frissítése a törölt elem nélkül
      const updatedVisits = ticket.visits.filter((_, i) => i !== index);

      await updateDoc(ticketRef, {
        visits: updatedVisits,
        history: arrayUnion(historyEntry),
        updatedAt: serverTimestamp()
      });

      // Lokális state frissítése
      setTicket(prev => ({
        ...prev,
        visits: updatedVisits,
        history: [...(prev.history || []), historyEntry]
      }));

      setDeletingVisit(null); // Törlés modal bezárása
    } catch (err) {
      console.error('Kiszállás törlési hiba:', err);
      setError('Hiba t��rtént a kiszállás törlésekor: ' + err.message);
    }
  };

  // Törlés kezelő függvény
  const handleDeleteTicket = async () => {
    try {
      await sendNotificationToOrgUsers({
        title: 'Ticket törölve',
        message: `${currentUser.displayName || currentUser.email} törölte a "${ticket.title}" ticketet`,
        type: 'ticket_deleted',
        link: `/organization/${orgId}/tickets`,
        metadata: {
          ticketId,
          ticketTitle: ticket.title
        }
      }, currentUser);

      // Existing deletion logic
      const ticketRef = doc(db, 'tickets', ticketId);
      await deleteDoc(ticketRef);
      navigate(`/organization/${orgId}/tickets`);
    } catch (err) {
      console.error('Ticket törlési hiba:', err);
      setError('Hiba történt a ticket törlésekor: ' + err.message);
    }
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-500">{error}</div>;
  if (!ticket) return <div>Ticket nem található</div>;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="md:flex md:items-center md:justify-between mb-6">
        <div className="flex-1 min-w-0">
          <div className="flex items-center">
            {!isEditing ? (
              <>
                <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                  #{ticket.ticketNumber || '---'} - {ticket.title}
                </h2>
              </>
            ) : null}
          </div>
        </div>
        <div className="mt-4 flex md:mt-0 md:ml-4 space-x-2">
          <button
            onClick={() => navigate(`/organization/${orgId}/tickets`)}
            className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Vissza
          </button>
          <button
            onClick={() => setIsDeletingTicket(true)}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          >
            Törlés
          </button>
          {!isEditing ? (
            <button
              onClick={() => setIsEditing(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              disabled={updating}
            >
              Szerkesztés
            </button>
          ) : null}
          
          {/* Státusz módosító dropdown */}
          <div className="relative inline-block text-left" ref={menuRef}>
            <div>
              <button
                type="button"
                className={`inline-flex justify-between items-center w-50 px-4 py-2 border rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 
                  ${getStatusButtonStyle(ticket.status)}`}
                id="status-menu"
                aria-expanded={isMenuOpen}
                aria-haspopup="true"
                disabled={updating}
                onClick={() => setIsMenuOpen(!isMenuOpen)}
              >
                <span>{getStatusText(ticket.status)}</span>
                <svg 
                  className={`ml-2 h-5 w-5 transition-transform duration-200 ${isMenuOpen ? 'transform rotate-180' : ''}`} 
                  xmlns="http://www.w3.org/2000/svg" 
                  viewBox="0 0 20 20" 
                  fill="currentColor"
                >
                  <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd" />
                </svg>
              </button>
            </div>

            {/* Státusz választó dropdown menü */}
            {isMenuOpen && (
              <div className="origin-top-right absolute right-0 mt-2 w-60 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-100">
                <div className="py-1">
                  {['new', 'in_progress', 'waiting_for_client', 'waiting_for_third_party', 'collector', 'resolved', 'closed'].map((status) => (
                    <button
                      key={status}
                      onClick={() => handleStatusChange(status)}
                      disabled={ticket.status === status || updating}
                      className={`w-full text-left px-4 py-2 text-sm ${
                        getStatusButtonStyle(status)
                      } flex justify-between items-center hover:opacity-80`}
                    >
                      <span>{getStatusText(status)}</span>
                      {ticket.status === status && (
                        <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                          <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                        </svg>
                      )}
                    </button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Szerkesztő űrlap */}
      {isEditing ? (
        <form onSubmit={handleEdit} className="space-y-6 bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Tárgy
              </label>
              <input
                type="text"
                name="title"
                id="title"
                value={editForm.title}
                onChange={(e) => setEditForm(prev => ({ ...prev, title: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                required
              />
            </div>

            <div>
              <label htmlFor="deadline" className="block text-sm font-medium text-gray-700">
                Határidő
              </label>
              <input
                type="date"
                name="deadline"
                id="deadline"
                value={editForm.deadline}
                onChange={(e) => setEditForm(prev => ({ ...prev, deadline: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>

            <div>
              <label htmlFor="clientId" className="block text-sm font-medium text-gray-700">
                Ügyfél
              </label>
              <select
                id="clientId"
                name="clientId"
                value={editForm.clientId}
                onChange={(e) => {
                  setEditForm(prev => ({ 
                    ...prev, 
                    clientId: e.target.value,
                    contact: null // Reset contact when client changes
                  }));
                }}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="">Válassz ügyfelet...</option>
                {clients.map(client => (
                  <option key={client.id} value={client.id}>{client.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label htmlFor="contact" className="block text-sm font-medium text-gray-700">
                Bejelentő
              </label>
              <select
                id="contact"
                name="contact"
                value={editForm.contact?.email || ''}
                onChange={(e) => {
                  const selectedClient = clients.find(c => c.id === editForm.clientId);
                  const selectedContact = selectedClient?.contacts?.find(c => c.email === e.target.value);
                  setEditForm(prev => ({ ...prev, contact: selectedContact }));
                }}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                disabled={!editForm.clientId}
              >
                <option value="">Válassz kapcsolattartót...</option>
                {clients
                  .find(c => c.id === editForm.clientId)
                  ?.contacts?.map((contact, index) => (
                    <option key={index} value={contact.email}>{contact.name}</option>
                  ))}
              </select>
            </div>

            <div>
              <label htmlFor="status" className="block text-sm font-medium text-gray-700">
                Státusz
              </label>
              <select
                id="status"
                name="status"
                value={editForm.status}
                onChange={(e) => setEditForm(prev => ({ ...prev, status: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 text-sm"
              >
                <option value="new">Új</option>
                <option value="in_progress">Folyamatban</option>
                <option value="waiting_for_client">Ügyfél válaszára vár</option>
                <option value="waiting_for_third_party">Harmadik fél válaszára vár</option>
                <option value="collector">Gyűjtő ticket</option>
                <option value="resolved">Megoldva</option>
                <option value="closed">Lezárva</option>
              </select>
            </div>

            <div>
              <label htmlFor="priority" className="block text-sm font-medium text-gray-700">
                Prioritás
              </label>
              <select
                id="priority"
                name="priority"
                value={editForm.priority}
                onChange={(e) => setEditForm(prev => ({ ...prev, priority: e.target.value }))}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              >
                <option value="low">Alacsony</option>
                <option value="normal">Normál</option>
                <option value="high">Magas</option>
                <option value="urgent">Sürgős</option>
              </select>
            </div>
          </div>

          <div>
            <label htmlFor="description" className="block text-sm font-medium text-gray-700">
              Leírás
            </label>
            <textarea
              id="description"
              name="description"
              rows={4}
              value={editForm.description}
              onChange={(e) => setEditForm(prev => ({ ...prev, description: e.target.value }))}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            />
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsEditing(false)}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
            >
              Mégse
            </button>
            <button
              type="submit"
              className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
            >
              Mentés
            </button>
          </div>
        </form>
      ) : (
        <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
          <div className="prose max-w-none">
            <p>{ticket.description}</p>
          </div>
        </div>
      )}

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            {ticket.title}
          </h3>
          <div className="text-sm text-gray-500">
            Létrehozva: {formatDate(ticket.createdAt)}
          </div>
        </div>
        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Ügyfél</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {clients.find(c => c.id === ticket.clientId)?.name || 'Ismeretlen ügyfél'}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Bejelentő</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <div>
                  {ticket.contact?.name || 'Nincs megadva'}
                  {ticket.contact?.email && (
                    <div className="text-sm text-gray-500">
                      <a href={`mailto:${ticket.contact.email}`} className="text-indigo-600 hover:text-indigo-900">
                        {ticket.contact.email}
                      </a>
                    </div>
                  )}
                </div>
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Státusz</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(ticket.status)}`}>
                  {getStatusText(ticket.status)}
                </span>
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Prioritás</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.priority}
              </dd>
            </div>
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Létrehozta</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.createdBy?.email}
              </dd>
            </div>
            {ticket.updatedAt && (
              <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Utoljára módosítva</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                  {formatDate(ticket.updatedAt)}
                  {ticket.updatedBy?.email && ` - ${ticket.updatedBy.email}`}
                </dd>
              </div>
            )}
            <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Leírás</dt>
              <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                {ticket.description}
              </dd>
            </div>
            <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
              <dt className="text-sm font-medium text-gray-500">Határidő</dt>
              <dd className={`mt-1 text-sm sm:mt-0 sm:col-span-2 ${
                ticket.deadline && new Date(ticket.deadline) < new Date() ? 'text-red-600 font-medium' : 'text-gray-900'
              }`}>
                {ticket.deadline ? formatDate(ticket.deadline) : 'Nincs határidő'}
              </dd>
            </div>
          </dl>
        </div>
      </div>

      {/* Két oszlopos elrendezés */}
      <div className="mt-8 grid grid-cols-1 lg:grid-cols-2 gap-8">
        {/* Munkaidő nyilvántartás szekció */}
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Munkaidő nyilvántartás
              </h3>
              <span className="text-sm font-medium text-gray-500">
                Összes munkaidő: {formatWorkTime(totalWorkTime)}
              </span>
            </div>
            
            {/* Munkaidő lista */}
            <div className="space-y-4 mb-6 max-h-[600px] overflow-y-auto">
              {ticket.workTimes && ticket.workTimes.length > 0 ? (
                ticket.workTimes.map((workTimeEntry, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    {editingWorkTime?.index === index ? (
                      // Szerkesztő form
                      <form onSubmit={(e) => handleEditWorkTime(e, index)} className="space-y-4">
                        <div className="grid grid-cols-3 gap-4">
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Dátum
                            </label>
                            <input
                              type="date"
                              className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                              value={editingWorkTime.date}
                              onChange={(e) => setEditingWorkTime(prev => ({ ...prev, date: e.target.value }))}
                              max={new Date().toISOString().split('T')[0]}
                              required
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Óra
                            </label>
                            <input
                              type="number"
                              min="0"
                              max="999"
                              className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                              value={editingWorkTime.hours}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
                                  setEditingWorkTime(prev => ({ ...prev, hours: value }));
                                }
                              }}
                            />
                          </div>
                          <div>
                            <label className="block text-sm font-medium text-gray-700">
                              Perc
                            </label>
                            <input
                              type="number"
                              min="0"
                              max="59"
                              className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                              value={editingWorkTime.minutes}
                              onChange={(e) => {
                                const value = e.target.value;
                                if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                                  setEditingWorkTime(prev => ({ ...prev, minutes: value }));
                                }
                              }}
                            />
                          </div>
                        </div>

                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Leírás
                          </label>
                          <textarea
                            rows={2}
                            className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                            value={editingWorkTime.description}
                            onChange={(e) => setEditingWorkTime(prev => ({ ...prev, description: e.target.value }))}
                          />
                        </div>

                        <div className="flex justify-end space-x-2">
                          <button
                            type="button"
                            onClick={() => setEditingWorkTime(null)}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mégse
                          </button>
                          <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mentés
                          </button>
                        </div>
                      </form>
                    ) : (
                      // Megjelenítés
                      <>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">
                              {workTimeEntry.createdBy.displayName || workTimeEntry.createdBy.name || workTimeEntry.createdBy.email}
                            </span>
                            <span className="text-sm text-gray-500">
                              {formatDate(workTimeEntry.date || workTimeEntry.createdAt)}
                            </span>
                            {workTimeEntry.updatedAt && (
                              <span className="text-sm text-gray-500 italic">
                                (módosítva: {formatDate(workTimeEntry.updatedAt)})
                              </span>
                            )}
                          </div>
                          <div className="flex items-center space-x-2">
                            <span className="text-sm font-medium text-gray-700">
                              {formatWorkTime(workTimeEntry.minutes)}
                            </span>
                            <button
                              onClick={() => startEditing(workTimeEntry, index)}
                              className="text-indigo-600 hover:text-indigo-900"
                              title="Szerkesztés"
                            >
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                              </svg>
                            </button>
                            <button
                              onClick={() => setDeletingWorkTime(index)}
                              className="text-red-600 hover:text-red-900"
                              title="Törlés"
                            >
                              <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                              </svg>
                            </button>
                          </div>
                        </div>
                        <p className="text-gray-700 whitespace-pre-wrap">
                          {workTimeEntry.description}
                        </p>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center py-4">
                  Még nincs rögzített munkaidő
                </p>
              )}
            </div>

            {/* Új munkaidő form */}
            <form onSubmit={handleAddWorkTime} className="mt-6 space-y-4">
              <div className="grid grid-cols-3 gap-4">
                <div>
                  <label htmlFor="workTimeDate" className="block text-sm font-medium text-gray-700">
                    Dátum
                  </label>
                  <input
                    type="date"
                    id="workTimeDate"
                    name="date"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={workTime.date}
                    onChange={(e) => setWorkTime(prev => ({ ...prev, date: e.target.value }))}
                    max={new Date().toISOString().split('T')[0]}
                    required
                  />
                </div>
                <div>
                  <label htmlFor="workTimeHours" className="block text-sm font-medium text-gray-700">
                    Óra
                  </label>
                  <input
                    type="number"
                    id="workTimeHours"
                    name="hours"
                    min="0"
                    max="999"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={workTime.hours}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 999)) {
                        setWorkTime(prev => ({ ...prev, hours: value }));
                      }
                    }}
                    disabled={workTime.isSubmitting}
                  />
                </div>
                <div>
                  <label htmlFor="workTimeMinutes" className="block text-sm font-medium text-gray-700">
                    Perc
                  </label>
                  <input
                    type="number"
                    id="workTimeMinutes"
                    name="minutes"
                    min="0"
                    max="59"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    value={workTime.minutes}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (value === '' || (parseInt(value) >= 0 && parseInt(value) <= 59)) {
                        setWorkTime(prev => ({ ...prev, minutes: value }));
                      }
                    }}
                    disabled={workTime.isSubmitting}
                  />
                </div>
              </div>
              
              {/* Description field remains the same */}
              <div>
                <label htmlFor="workTimeDescription" className="block text-sm font-medium text-gray-700">
                  Leírás
                </label>
                <textarea
                  id="workTimeDescription"
                  name="description"
                  rows={2}
                  className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Végzett munka leírása..."
                  value={workTime.description}
                  onChange={(e) => setWorkTime(prev => ({ ...prev, description: e.target.value }))}
                  disabled={workTime.isSubmitting}
                />
              </div>
              
              {/* Submit button remains the same */}
              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={
                    workTime.isSubmitting || 
                    !workTime.description.trim() || 
                    (workTime.hours === '' && workTime.minutes === '') ||
                    (parseInt(workTime.hours || 0) === 0 && parseInt(workTime.minutes || 0) === 0)
                  }
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                    ${workTime.isSubmitting || !workTime.description.trim() || (workTime.hours === '' && workTime.minutes === '')
                      ? 'bg-gray-300 cursor-not-allowed' 
                      : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    }`}
                >
                  {workTime.isSubmitting ? 'Mentés...' : 'Munkaidő rögzítése'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Hozzászólások szekció */}
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
              Hozzászólások
            </h3>
            
            {/* Komment lista */}
            <div className="space-y-4 mb-6 max-h-[600px] overflow-y-auto">
              {ticket.comments && ticket.comments.length > 0 ? (
                ticket.comments.map((comment, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    {editingComment?.index === index ? (
                      // Szerkesztő form
                      <form onSubmit={(e) => handleEditComment(e, index)} className="space-y-4">
                        <textarea
                          rows={3}
                          className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          value={editingComment.content}
                          onChange={(e) => setEditingComment(prev => ({ ...prev, content: e.target.value }))}
                        />
                        <div className="flex justify-end space-x-2">
                          <button
                            type="button"
                            onClick={() => setEditingComment(null)}
                            className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mégse
                          </button>
                          <button
                            type="submit"
                            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          >
                            Mentés
                          </button>
                        </div>
                      </form>
                    ) : (
                      <>
                        <div className="flex items-center justify-between mb-2">
                          <div className="flex items-center space-x-2">
                            <span className="font-medium text-gray-900">
                              {comment.createdBy.displayName || comment.createdBy.name || comment.createdBy.email}
                            </span>
                            <span className="text-sm text-gray-500">
                              {formatDate(comment.createdAt)}
                            </span>
                            {comment.updatedAt && (
                              <span className="text-sm text-gray-500 italic">
                                (módosítva: {formatDate(comment.updatedAt)})
                              </span>
                            )}
                          </div>
                          {/* Csak a saját kommentjeinket szerkeszthetjük/törölhetjük */}
                          {comment.createdBy.id === currentUser.uid && (
                            <div className="flex items-center space-x-2">
                              <button
                                onClick={() => setEditingComment({ index, content: comment.content })}
                                className="text-indigo-600 hover:text-indigo-900"
                                title="Szerkesztés"
                              >
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                              </button>
                              <button
                                onClick={() => setDeletingComment(index)}
                                className="text-red-600 hover:text-red-900"
                                title="Törlés"
                              >
                                <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                                  <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                                </svg>
                              </button>
                            </div>
                          )}
                        </div>
                        <p className="text-gray-700 whitespace-pre-wrap">
                          {comment.content}
                        </p>
                      </>
                    )}
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center py-4">
                  Még nincsenek hozzászólások
                </p>
              )}
            </div>

            {/* Új komment form */}
            <form onSubmit={handleAddComment} className="mt-6">
              <div>
                <label htmlFor="comment" className="sr-only">
                  Új hozzászólás
                </label>
                <textarea
                  id="comment"
                  name="comment"
                  rows={3}
                  className="shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Írja be hozzászólását..."
                  value={comment}
                  onChange={(e) => setComment(e.target.value)}
                  disabled={isSubmittingComment}
                />
              </div>
              <div className="mt-3 flex justify-end">
                <button
                  type="submit"
                  disabled={isSubmittingComment || !comment.trim()}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                    ${isSubmittingComment || !comment.trim() 
                      ? 'bg-gray-300 cursor-not-allowed' 
                      : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    }`}
                >
                  {isSubmittingComment ? 'Küldés...' : 'Hozzászólás küldése'}
                </button>
              </div>
            </form>
          </div>
        </div>

        {/* Kiszállások szekció */}
        <div className="bg-white shadow sm:rounded-lg">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-between mb-4">
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Kiszállások
              </h3>
              <span className="text-sm font-medium text-gray-500">
                Összes kiszállás: {ticket.visits?.length || 0} db
              </span>
            </div>

            {/* Kiszállás lista */}
            <div className="space-y-4 mb-6 max-h-[600px] overflow-y-auto">
              {ticket.visits && ticket.visits.length > 0 ? (
                ticket.visits.map((visitEntry, index) => (
                  <div key={index} className="bg-gray-50 rounded-lg p-4">
                    <div className="flex items-center justify-between mb-2">
                      <div className="flex items-center space-x-2">
                        <span className="font-medium text-gray-900">
                          {visitEntry.createdBy.displayName || visitEntry.createdBy.name || visitEntry.createdBy.email}
                        </span>
                        <span className="text-sm text-gray-500">
                          Dátum: {format(new Date(visitEntry.date), 'yyyy.MM.dd')}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <button
                          onClick={() => setDeletingVisit(index)}
                          className="text-red-600 hover:text-red-900"
                          title="Törlés"
                        >
                          <svg className="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                          </svg>
                        </button>
                      </div>
                    </div>
                    <p className="text-gray-700 whitespace-pre-wrap">
                      {visitEntry.description}
                    </p>
                  </div>
                ))
              ) : (
                <p className="text-gray-500 text-center py-4">
                  Még nincs rögzített kiszállás
                </p>
              )}
            </div>

            {/* Új kiszállás form */}
            <form onSubmit={handleAddVisit} className="mt-6 space-y-4">
              <div>
                <label htmlFor="visitDate" className="block text-sm font-medium text-gray-700">
                  Dátum
                </label>
                <input
                  type="date"
                  id="visitDate"
                  name="date"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={visit.date}
                  onChange={(e) => setVisit(prev => ({ ...prev, date: e.target.value }))}
                  required
                />
              </div>
              
              <div>
                <label htmlFor="visitDescription" className="block text-sm font-medium text-gray-700">
                  Leírás
                </label>
                <textarea
                  id="visitDescription"
                  name="description"
                  rows={2}
                  className="mt-1 shadow-sm block w-full focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm border border-gray-300 rounded-md"
                  placeholder="Kiszállás leírása..."
                  value={visit.description}
                  onChange={(e) => setVisit(prev => ({ ...prev, description: e.target.value }))}
                  required
                />
              </div>

              <div className="flex justify-end">
                <button
                  type="submit"
                  disabled={visit.isSubmitting || !visit.description.trim() || !visit.date}
                  className={`inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                    ${visit.isSubmitting || !visit.description.trim() || !visit.date
                      ? 'bg-gray-300 cursor-not-allowed' 
                      : 'bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'
                    }`}
                >
                  {visit.isSubmitting ? 'Mentés...' : 'Kiszállás rögzítése'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      {/* Törlés megerősítő modal */}
      {deletingWorkTime !== null && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Munkaidő törlése
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Biztosan törölni szeretné ezt a munkaidő bejegyzést? Ezt a műveletet nem lehet visszavonni.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleDeleteWorkTime(deletingWorkTime)}
                >
                  Törlés
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeletingWorkTime(null)}
                >
                  Mégse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Törlés megerősítő modal kommentekhez */}
      {deletingComment !== null && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Hozzászólás törlése
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Biztosan törölni szeretné ezt a hozzászólást? Ezt a műveletet nem lehet visszavonni.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleDeleteComment(deletingComment)}
                >
                  Törlés
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeletingComment(null)}
                >
                  Mégse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* Ticket története */}
      <div className="mt-8 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <h3 className="text-lg leading-6 font-medium text-gray-900 mb-4">
            Ticket története
          </h3>
          <div className="flow-root">
            <ul className="-mb-8">
              {ticket.history?.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp)).map((event, index) => (
                <li key={index}>
                  <div className="relative pb-8">
                    {index !== ticket.history.length - 1 && (
                      <span className="absolute top-4 left-4 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
                    )}
                    <div className="relative flex space-x-3">
                      <div>
                        <span className="h-8 w-8 rounded-full bg-gray-100 flex items-center justify-center ring-8 ring-white">
                          <svg className="h-5 w-5 text-gray-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                          </svg>
                        </span>
                      </div>
                      <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                        <div>
                          <p className="text-sm text-gray-500">
                            <span className="font-medium text-gray-900">
                              {event.userDisplayName || 'Rendszer'}
                            </span>
                            {' - '}
                            <span className="font-medium text-indigo-600">
                              {getActionText(event.action)}
                            </span>
                            {' - '}
                            {event.details}
                          </p>
                        </div>
                        <div className="text-right text-sm whitespace-nowrap text-gray-500">
                          {formatDate(event.timestamp)}
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
      {deletingVisit !== null && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Kiszállás törlése
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Biztosan törölni szeretné ezt a kiszállást? Ezt a műveletet nem lehet visszavonni.
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => handleDeleteVisit(deletingVisit)}
                >
                  Törlés
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setDeletingVisit(null)}
                >
                  Mégse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {isDeletingTicket && (
        <div className="fixed z-10 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" aria-hidden="true"></div>
            <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
              <div className="sm:flex sm:items-start">
                <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                  <svg className="h-6 w-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
                  </svg>
                </div>
                <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                  <h3 className="text-lg leading-6 font-medium text-gray-900" id="modal-title">
                    Ticket törlése
                  </h3>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Biztosan törölni szeretné ezt a ticketet? A művelet nem visszavonható.
                    A ticket törlése után az alábbi helyeken nem lesz elérhető:
                    <ul className="mt-2 list-disc list-inside text-sm text-gray-500">
                      <li>Ticket lista oldalon</li>
                      <li>Keresési találatokban</li>
                      <li>Jelentésekben és statisztikákban</li>
                      <li>Kapcsolódó értesítésekben</li>
                    </ul>
                    </p>
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={handleDeleteTicket}
                >
                  Törlés
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm"
                  onClick={() => setIsDeletingTicket(false)}
                >
                  Mégse
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { Link } from 'react-router-dom';
import Avatar from '../common/Avatar';
import { toast } from 'react-hot-toast';

// Előre definiált színek a projektekhez
export const PROJECT_COLORS = {
  blue: 'bg-blue-100 border-blue-300',
  darkBlue: 'bg-blue-200 border-blue-400',
  green: 'bg-green-100 border-green-300',
  darkGreen: 'bg-green-200 border-green-400',
  yellow: 'bg-yellow-100 border-yellow-300',
  darkYellow: 'bg-yellow-200 border-yellow-400',
  cyberYellow: 'bg-yellow-300 border-yellow-500',
  red: 'bg-red-100 border-red-300',
  darkRed: 'bg-red-200 border-red-400',
  purple: 'bg-purple-100 border-purple-300',
  darkPurple: 'bg-purple-200 border-purple-400',
  pink: 'bg-pink-100 border-pink-300',
  darkPink: 'bg-pink-200 border-pink-400',
  indigo: 'bg-indigo-100 border-indigo-300',
  darkIndigo: 'bg-indigo-200 border-indigo-400',
  orange: 'bg-orange-100 border-orange-300',
  darkOrange: 'bg-orange-200 border-orange-400',
  teal: 'bg-teal-100 border-teal-300',
  darkTeal: 'bg-teal-200 border-teal-400',
  cyan: 'bg-cyan-100 border-cyan-300',
  darkCyan: 'bg-cyan-200 border-cyan-400',
  lime: 'bg-lime-100 border-lime-300',
  darkLime: 'bg-lime-200 border-lime-400',
  emerald: 'bg-emerald-100 border-emerald-300',
  darkEmerald: 'bg-emerald-200 border-emerald-400',
  rose: 'bg-rose-100 border-rose-300',
  darkRose: 'bg-rose-200 border-rose-400',
  sky: 'bg-sky-100 border-sky-300',
  darkSky: 'bg-sky-200 border-sky-400',
};

// Segédfüggvény a dátum formázásához
const formatDate = (date) => {
  if (!date) return null;
  
  try {
    // Ha Timestamp objektum
    if (date?.toDate) {
      return date.toDate().toLocaleDateString('hu-HU', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      });
    }
    
    // Ha ISO string vagy más dátum string
    return new Date(date).toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return null;
  }
};

const PROJECT_STATUSES = {
  active: {
    label: 'Aktív',
    class: 'bg-green-100 text-green-800',
    icon: '🟢'
  },
  on_hold: {
    label: 'Függőben',
    class: 'bg-yellow-100 text-yellow-800',
    icon: '🟡'
  },
  completed: {
    label: 'Lezárt',
    class: 'bg-blue-100 text-blue-800',
    icon: '🔵'
  },
  cancelled: {
    label: 'Megszakítva',
    class: 'bg-red-100 text-red-800',
    icon: '🔴'
  }
};

export default function ProjectList({ organizationId }) {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const { currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [statusFilter, setStatusFilter] = useState('all');
  const [viewMode, setViewMode] = useState('list');
  const [projectMembers, setProjectMembers] = useState({});
  const [users, setUsers] = useState({});
  const [pinnedProjects, setPinnedProjects] = useState(new Set());

  useEffect(() => {
    async function fetchProjects() {
      try {
        const projectsRef = collection(db, 'projects');
        const q = query(projectsRef, where('organizationId', '==', organizationId));
        const querySnapshot = await getDocs(q);
        
        const projectsData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        
        setProjects(projectsData);
      } catch (error) {
        console.error('Hiba a projektek betöltésekor:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchProjects();
  }, [organizationId]);

  useEffect(() => {
    const filtered = projects.filter(project => {
      const matchesSearch = 
        project.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.description?.toLowerCase().includes(searchTerm.toLowerCase()) ||
        project.code?.toLowerCase().includes(searchTerm.toLowerCase());
      
      const matchesStatus = statusFilter === 'all' || project.status === statusFilter;
      
      return matchesSearch && matchesStatus;
    });
    setFilteredProjects(filtered);
  }, [projects, searchTerm, statusFilter]);

  useEffect(() => {
    console.log('Project Members:', projectMembers);
    console.log('Users:', users);
  }, [projectMembers, users]);

  const fetchProjectMembers = async () => {
    try {
      const newProjectMembers = {};
      const newUsers = {};

      for (const project of projects) {
        const tasksRef = collection(db, 'tasks');
        const q = query(tasksRef, where('projectId', '==', project.id));
        const taskSnapshot = await getDocs(q);
        
        const memberIds = new Set();
        
        taskSnapshot.docs.forEach(doc => {
          const task = doc.data();
          if (task.assignedUsers && Array.isArray(task.assignedUsers)) {
            task.assignedUsers.forEach(userId => memberIds.add(userId));
          }
          if (task.createdBy) {
            memberIds.add(task.createdBy);
          }
        });

        // Felhasználók adatainak lekérése
        for (const userId of memberIds) {
          if (!newUsers[userId]) {
            const userDoc = await getDoc(doc(db, 'users', userId));
            if (userDoc.exists()) {
              const userData = userDoc.data();
              newUsers[userId] = {
                id: userId,
                displayName: userData.name || userData.email,
                email: userData.email,
                photoURL: userData.photoURL,
                role: userData.role
              };
            }
          }
        }

        newProjectMembers[project.id] = Array.from(memberIds);
      }

      setProjectMembers(newProjectMembers);
      setUsers(prev => ({ ...prev, ...newUsers }));
      
    } catch (error) {
      console.error('Hiba a projekt tagok betöltésekor:', error);
    }
  };

  useEffect(() => {
    if (projects.length > 0) {
      fetchProjectMembers();
    }
  }, [projects]);

  // Pinned projektek betöltése
  useEffect(() => {
    const loadPinnedProjects = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          setPinnedProjects(new Set(userData.pinnedProjects || []));
        }
      } catch (error) {
        console.error('Hiba a rögzített projektek betöltésekor:', error);
      }
    };

    loadPinnedProjects();
  }, [currentUser.uid]);

  // Pin/Unpin kezelése
  const handlePinToggle = async (projectId, event) => {
    event.preventDefault(); // Link kattintás megakadályozása
    event.stopPropagation();
    
    try {
      const userRef = doc(db, 'users', currentUser.uid);
      const newPinnedProjects = new Set(pinnedProjects);
      
      if (newPinnedProjects.has(projectId)) {
        newPinnedProjects.delete(projectId);
        toast.success('Projekt eltávolítva a rögzítettek közül');
      } else {
        newPinnedProjects.add(projectId);
        toast.success('Projekt rögzítve');
      }
      
      await updateDoc(userRef, {
        pinnedProjects: Array.from(newPinnedProjects)
      });
      
      setPinnedProjects(newPinnedProjects);
    } catch (error) {
      console.error('Hiba a projekt rögzítésekor:', error);
      toast.error('Hiba történt a művelet során');
    }
  };

  // Rendezzük a projekteket (rögzítettek előre)
  const sortedProjects = [...filteredProjects].sort((a, b) => {
    const aIsPinned = pinnedProjects.has(a.id);
    const bIsPinned = pinnedProjects.has(b.id);
    if (aIsPinned && !bIsPinned) return -1;
    if (!aIsPinned && bIsPinned) return 1;
    return 0;
  });

  const ProjectListItem = ({ project }) => {
    const members = projectMembers[project.id] || [];
    const isPinned = pinnedProjects.has(project.id);
    
    // Státusz színek meghatározása
    const statusColors = {
      active: 'bg-green-500',
      on_hold: 'bg-yellow-500',
      completed: 'bg-blue-500',
      cancelled: 'bg-red-500'
    };

    return (
      <li className="relative hover:bg-gray-50 transition-colors duration-200">
        {/* Státusz vonal */}
        <div 
          className={`absolute left-0 top-0 bottom-0 w-1 ${statusColors[project.status] || 'bg-gray-300'}`}
        />
        
        <Link
          to={`/organization/${organizationId}/projects/${project.id}`}
          className="block pl-4" // Padding-left a státusz vonal miatt
        >
          <div className="px-4 py-4">
            <div className="flex items-center justify-between">
              <div className="flex items-center space-x-3">
                <div className={`w-3 h-3 rounded-full ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-') || 'bg-gray-200'}`} />
                <div className="min-w-0 flex-1">
                  <div className="flex items-center space-x-2">
                    <h3 className="text-sm font-medium text-gray-900">
                      {project.name}
                    </h3>
                    <span className="text-xs font-mono text-gray-500 bg-gray-100 px-2 py-0.5 rounded">
                      {project.code}
                    </span>
                    <button
                      onClick={(e) => handlePinToggle(project.id, e)}
                      className={`p-1 rounded-full hover:bg-gray-100 transition-colors duration-200 ${
                        isPinned ? 'text-indigo-600' : 'text-gray-400'
                      }`}
                    >
                      <svg 
                        className="h-4 w-4" 
                        fill={isPinned ? 'currentColor' : 'none'} 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                        />
                      </svg>
                    </button>
                  </div>
                  <p className="mt-1 text-xs text-gray-400 line-clamp-1">
                    {project.description}
                  </p>
                  
                  {members.length > 0 && (
                    <div className="mt-2 flex items-center space-x-2">
                      <div className="flex -space-x-1">
                        {members.slice(0, 3).map(memberId => {
                          const user = users[memberId];
                          if (!user) return null;

                          return (
                            <div key={memberId} className="relative">
                              <Avatar
                                user={{
                                  displayName: user.displayName,
                                  email: user.email,
                                  photoURL: user.photoURL
                                }}
                                size="sm"
                              />
                              {user.role === 'project_manager' && (
                                <span className="absolute -bottom-0.5 -right-0.5 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
                              )}
                            </div>
                          );
                        })}
                      </div>
                      {members.length > 3 && (
                        <span className="text-xs text-gray-500">
                          +{members.length - 3} további tag
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>
              
              <div className="flex items-center space-x-4">
                <div className="text-xs text-gray-500">
                  <span className="font-medium">Határidő:</span>{' '}
                  {project.deadline && formatDate(project.deadline) 
                    ? formatDate(project.deadline)
                    : 'Nincs megadva'
                  }
                </div>
                
                <svg 
                  className="h-4 w-4 text-gray-400" 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path 
                    strokeLinecap="round" 
                    strokeLinejoin="round" 
                    strokeWidth={2} 
                    d="M9 5l7 7-7 7" 
                  />
                </svg>
              </div>
            </div>
          </div>
        </Link>
      </li>
    );
  };

  if (loading) return <div>Betöltés...</div>;

  return (
    <div className="bg-gray-50 rounded-lg shadow-sm p-3 sm:p-6">
      <div className="flex flex-col space-y-4 mb-4 sm:mb-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between">
          <div>
            <h2 className="text-lg sm:text-2xl font-bold text-gray-900">Projektek</h2>
            <p className="mt-1 text-xs sm:text-sm text-gray-500">
              Aktív projektek listája és kezelése
            </p>
          </div>
          
          <div className="flex flex-col sm:flex-row gap-2 sm:gap-4 mt-4 sm:mt-0">
            {/* Státusz szűrő - átalakítva gombokká */}
            <div className="flex bg-white rounded-lg shadow-sm p-1 border border-gray-200">
              <button
                onClick={() => setStatusFilter('all')}
                className={`flex items-center px-3 py-1.5 rounded text-sm font-medium transition-colors duration-200 ${
                  statusFilter === 'all'
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Összes
              </button>
              <button
                onClick={() => setStatusFilter('active')}
                className={`flex items-center px-3 py-1.5 rounded text-sm font-medium transition-colors duration-200 ${
                  statusFilter === 'active'
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Aktív
              </button>
              <button
                onClick={() => setStatusFilter('completed')}
                className={`flex items-center px-3 py-1.5 rounded text-sm font-medium transition-colors duration-200 ${
                  statusFilter === 'completed'
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                Lezárt
              </button>
            </div>

            {/* Nézet váltó */}
            <div className="flex bg-white rounded-lg shadow-sm p-1 border border-gray-200">
              <button
                onClick={() => setViewMode('list')}
                className={`flex items-center px-3 py-1.5 rounded text-sm font-medium transition-colors duration-200 ${
                  viewMode === 'list'
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 10h16M4 14h16M4 18h16" />
                </svg>
                Lista
              </button>
              <button
                onClick={() => setViewMode('grid')}
                className={`flex items-center px-3 py-1.5 rounded text-sm font-medium transition-colors duration-200 ${
                  viewMode === 'grid'
                    ? 'bg-indigo-100 text-indigo-700'
                    : 'text-gray-500 hover:text-gray-700'
                }`}
              >
                <svg className="h-4 w-4 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg>
                Grid
              </button>
            </div>
            
            {/* Új projekt gomb */}
            <Link
              to={`/organization/${organizationId}/projects/new`}
              className="inline-flex items-center justify-center px-3 sm:px-4 py-2 border border-transparent text-sm font-medium rounded-lg text-white bg-indigo-600 hover:bg-indigo-700"
            >
              <svg className="h-4 w-4 sm:h-5 sm:w-5 mr-1.5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span className="whitespace-nowrap">Új projekt</span>
            </Link>
          </div>
        </div>
      </div>
      
      {filteredProjects.length === 0 ? (
        <div className="text-center py-8 sm:py-12 bg-white rounded-lg border-2 border-dashed border-gray-300">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nincsenek projektek</h3>
          <p className="mt-1 text-sm text-gray-500">Hozz létre egy új projektet a kezdéshez.</p>
        </div>
      ) : viewMode === 'grid' ? (
        <div className="grid gap-3 sm:gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
          {sortedProjects.map(project => (
            <Link
              key={project.id}
              to={`/organization/${organizationId}/projects/${project.id}`}
              className="block group"
            >
              <div className={`relative h-[180px] sm:h-[200px] p-3 sm:p-6 rounded-lg shadow-sm border ${
                PROJECT_COLORS[project.color] || 'bg-white border-gray-200'
              } hover:shadow-md transition-all duration-200 flex flex-col`}>
                <div className="flex justify-between items-start mb-3">
                  <h3 className="text-lg font-semibold text-gray-900 group-hover:text-indigo-600 transition-colors duration-200">
                    {project.name}
                  </h3>
                  <span className="text-sm font-mono bg-white bg-opacity-75 px-2 py-1 rounded shadow-sm">
                    {project.code}
                  </span>
                </div>
                
                <p className="text-gray-600 text-sm mb-4 line-clamp-2">
                  {project.description}
                </p>
                
                {/* Projekt tagok megjelenítése */}
                {projectMembers[project.id]?.length > 0 && (
                  <div className="flex items-center space-x-2 mb-4">
                    <div className="flex -space-x-1">
                      {projectMembers[project.id].slice(0, 3).map(memberId => {
                        const user = users[memberId];
                        if (!user) return null;

                        return (
                          <div key={memberId} className="relative">
                            <Avatar
                              user={{
                                displayName: user.displayName,
                                email: user.email,
                                photoURL: user.photoURL
                              }}
                              size="sm"
                            />
                            {user.role === 'project_manager' && (
                              <span className="absolute -bottom-0.5 -right-0.5 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {projectMembers[project.id].length > 3 && (
                      <span className="text-xs text-gray-500">
                        +{projectMembers[project.id].length - 3} további tag
                      </span>
                    )}
                  </div>
                )}
                
                <div className="mt-auto">
                  <div className="text-sm text-gray-500 mb-4">
                    <span className="font-medium">Határidő:</span>{' '}
                    {project.deadline && formatDate(project.deadline) 
                      ? formatDate(project.deadline)
                      : 'Nincs megadva'
                    }
                  </div>
                  
                  <div className="flex justify-between items-center">
                    <span className="inline-flex items-center text-sm text-indigo-600 group-hover:text-indigo-800 font-medium transition-colors duration-200">
                      Részletek
                      <svg 
                        className="ml-2 h-4 w-4 transform group-hover:translate-x-1 transition-transform duration-200" 
                        fill="none" 
                        stroke="currentColor" 
                        viewBox="0 0 24 24"
                      >
                        <path 
                          strokeLinecap="round" 
                          strokeLinejoin="round" 
                          strokeWidth={2} 
                          d="M9 5l7 7-7 7" 
                        />
                      </svg>
                    </span>
                    
                    <span className={`inline-flex items-center gap-1 px-2.5 py-0.5 rounded-full text-xs font-medium ${PROJECT_STATUSES[project.status]?.class || 'bg-gray-100 text-gray-800'}`}>
                      {PROJECT_STATUSES[project.status]?.icon}
                      {PROJECT_STATUSES[project.status]?.label || 'Ismeretlen'}
                    </span>
                  </div>
                </div>

                {/* Hover overlay effect */}
                <div className="absolute inset-0 rounded-lg bg-indigo-500 opacity-0 group-hover:opacity-5 transition-opacity duration-200" />
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className="bg-white rounded-lg border border-gray-200 overflow-hidden">
          <ul className="divide-y divide-gray-200">
            {sortedProjects.map(project => (
              <li key={project.id} className="relative hover:bg-gray-50">
                {/* Új: Státusz vonal */}
                <div 
                  className={`absolute left-0 top-0 bottom-0 w-1 ${
                    project.status === 'active' ? 'bg-green-500' :
                    project.status === 'on_hold' ? 'bg-yellow-500' :
                    project.status === 'completed' ? 'bg-blue-500' :
                    project.status === 'cancelled' ? 'bg-red-500' :
                    'bg-gray-300'
                  }`}
                />
                
                <Link
                  to={`/organization/${organizationId}/projects/${project.id}`}
                  className="block pl-4" // Új: padding a vonal miatt
                >
                  <div className="px-4 py-4">
                    <div className="flex items-center justify-between">
                      <div className="flex items-center space-x-3">
                        <div className={`w-3 h-3 rounded-full ${PROJECT_COLORS[project.color]?.replace('bg-', 'bg-') || 'bg-gray-200'}`} />
                        <div className="min-w-0 flex-1">
                          <div className="flex items-center space-x-2">
                            <h3 className="text-sm font-medium text-gray-900">
                              {project.name}
                            </h3>
                            <span className="text-xs font-mono text-gray-500 bg-gray-100 px-2 py-0.5 rounded">
                              {project.code}
                            </span>
                            {/* Pin ikon */}
                            <button
                              onClick={(e) => handlePinToggle(project.id, e)}
                              className={`p-1 rounded-full hover:bg-gray-100 transition-colors duration-200 ${
                                pinnedProjects.has(project.id) ? 'text-indigo-600' : 'text-gray-400'
                              }`}
                            >
                              <svg 
                                className="h-4 w-4" 
                                fill={pinnedProjects.has(project.id) ? 'currentColor' : 'none'} 
                                stroke="currentColor" 
                                viewBox="0 0 24 24"
                              >
                                <path 
                                  strokeLinecap="round" 
                                  strokeLinejoin="round" 
                                  strokeWidth={2} 
                                  d="M5 5a2 2 0 012-2h10a2 2 0 012 2v16l-7-3.5L5 21V5z"
                                />
                              </svg>
                            </button>
                          </div>
                          <p className="mt-1 text-xs text-gray-400 line-clamp-1">
                            {project.description}
                          </p>
                          
                          {projectMembers[project.id]?.length > 0 && (
                            <div className="mt-2 flex items-center space-x-2">
                              <div className="flex -space-x-1">
                                {projectMembers[project.id].slice(0, 3).map(memberId => {
                                  const user = users[memberId];
                                  if (!user) return null;

                                  return (
                                    <div key={memberId} className="relative">
                                      <Avatar
                                        user={{
                                          displayName: user.displayName,
                                          email: user.email,
                                          photoURL: user.photoURL
                                        }}
                                        size="sm"
                                      />
                                      {user.role === 'project_manager' && (
                                        <span className="absolute -bottom-0.5 -right-0.5 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white" />
                                      )}
                                    </div>
                                  );
                                })}
                              </div>
                              {projectMembers[project.id].length > 3 && (
                                <span className="text-xs text-gray-500">
                                  +{projectMembers[project.id].length - 3} további tag
                                </span>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                      
                      <div className="flex items-center space-x-4">
                        <div className="text-xs text-gray-500">
                          <span className="font-medium">Határidő:</span>{' '}
                          {project.deadline && formatDate(project.deadline) 
                            ? formatDate(project.deadline)
                            : 'Nincs megadva'
                          }
                        </div>
                        
                        <svg 
                          className="h-4 w-4 text-gray-400" 
                          fill="none" 
                          stroke="currentColor" 
                          viewBox="0 0 24 24"
                        >
                          <path 
                            strokeLinecap="round" 
                            strokeLinejoin="round" 
                            strokeWidth={2} 
                            d="M9 5l7 7-7 7" 
                          />
                        </svg>
                      </div>
                    </div>
                  </div>
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
} 
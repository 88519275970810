import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc, deleteDoc } from 'firebase/firestore';
import LoadingScreen from '../ui/LoadingScreen';
import { PROJECT_COLORS } from './ProjectList';

const truncateName = (name, maxLength = 5) => {
  if (!name) return 'Betöltés...';
  return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
};

export default function ProjectSettings() {
  const [project, setProject] = useState(null);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [color, setColor] = useState('');
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const { orgId, projectId } = useParams();
  const navigate = useNavigate();
  const [deadline, setDeadline] = useState('');
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    async function fetchProject() {
      try {
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);
        
        if (!projectSnap.exists()) {
          setError('A projekt nem található');
          return;
        }

        const projectData = { id: projectSnap.id, ...projectSnap.data() };
        setProject(projectData);
        setName(projectData.name);
        setDescription(projectData.description || '');
        setCode(projectData.code || '');
        setColor(projectData.color || 'blue');
        if (projectData.deadline) {
          setDeadline(projectData.deadline.toDate().toISOString().split('T')[0]);
        }
      } catch (err) {
        console.error('Hiba a projekt betöltésekor:', err);
        setError('Hiba történt a projekt betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchProject();
  }, [projectId]);

  useEffect(() => {
    async function fetchOrgName() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    }
    fetchOrgName();
  }, [orgId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSaving(true);
    setError(null);

    try {
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, {
        name,
        description,
        code,
        color,
        deadline: deadline ? new Date(deadline) : null,
        updatedAt: new Date()
      });

      navigate(`/organization/${orgId}/projects/${projectId}`);
    } catch (err) {
      console.error('Hiba a projekt mentésekor:', err);
      setError('Hiba történt a projekt mentése során');
      setSaving(false);
    }
  };

  const handleDelete = async () => {
    try {
      await deleteDoc(doc(db, 'projects', projectId));
      navigate(`/organization/${orgId}/projects`);
    } catch (err) {
      console.error('Hiba a projekt törlésekor:', err);
      setError('Hiba történt a projekt törlése során');
    }
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center mt-4">{error}</div>;
  if (!project) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </Link>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to="/organizations" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Szervezetek</Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
            <span className="hidden sm:inline">{organizationName || 'Betöltés...'}</span>
            <span className="sm:hidden">{truncateName(organizationName)}</span>
          </Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}/projects`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Projektek</Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}/projects/${projectId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
            <span className="hidden sm:inline">{project?.name || 'Betöltés...'}</span>
            <span className="sm:hidden">{truncateName(project?.name)}</span>
          </Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li className="text-gray-500 font-medium">Beállítások</li>
        </ol>
      </nav>

      <div className="md:grid md:grid-cols-3 md:gap-6">
        <div className="md:col-span-1">
          <h3 className="text-lg font-medium leading-6 text-gray-900">Projekt beállítások</h3>
          <p className="mt-1 text-sm text-gray-500">
            Itt módosíthatod a projekt alapvető beállításait.
          </p>
        </div>
        <div className="mt-5 md:mt-0 md:col-span-2">
          <form onSubmit={handleSubmit}>
            <div className="shadow sm:rounded-md sm:overflow-hidden">
              <div className="px-4 py-5 bg-white space-y-6 sm:p-6">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Projekt neve
                  </label>
                  <input
                    type="text"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Projekt kód
                  </label>
                  <input
                    type="text"
                    value={code}
                    onChange={(e) => setCode(e.target.value.toUpperCase())}
                    required
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Szín
                  </label>
                  <div className="mt-1 flex flex-wrap gap-2">
                    {Object.keys(PROJECT_COLORS).map((colorKey) => (
                      <button
                        key={colorKey}
                        type="button"
                        onClick={() => setColor(colorKey)}
                        className={`w-8 h-8 rounded-full border-2 transition-all duration-200 ${
                          PROJECT_COLORS[colorKey]
                        } ${
                          color === colorKey 
                            ? 'ring-2 ring-offset-2 ring-indigo-500'
                            : 'hover:ring-2 hover:ring-offset-2 hover:ring-gray-300'
                        }`}
                        title={colorKey.charAt(0).toUpperCase() + colorKey.slice(1)}
                      />
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Leírás
                  </label>
                  <textarea
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    rows={4}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Határidő
                  </label>
                  <input
                    type="date"
                    value={deadline}
                    onChange={(e) => setDeadline(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>
              <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
                <button
                  type="submit"
                  disabled={saving}
                  className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {saving ? 'Mentés...' : 'Mentés'}
                </button>
              </div>
            </div>
          </form>

          {/* Veszélyes műveletek */}
          <div className="mt-10">
            <div className="bg-white shadow sm:rounded-lg">
              <div className="px-4 py-5 sm:p-6">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Projekt törlése
                </h3>
                <div className="mt-2 max-w-xl text-sm text-gray-500">
                  <p>
                    A projekt törlése után nincs lehetőség visszaállításra. Minden kapcsolódó adat elvész.
                  </p>
                </div>
                <div className="mt-5">
                  {!showDeleteConfirm ? (
                    <button
                      type="button"
                      onClick={() => setShowDeleteConfirm(true)}
                      className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-red-700 bg-red-100 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                    >
                      Projekt törlése
                    </button>
                  ) : (
                    <div className="space-x-3">
                      <button
                        type="button"
                        onClick={handleDelete}
                        className="inline-flex items-center justify-center px-4 py-2 border border-transparent font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:text-sm"
                      >
                        Igen, törlöm a projektet
                      </button>
                      <button
                        type="button"
                        onClick={() => setShowDeleteConfirm(false)}
                        className="inline-flex items-center justify-center px-4 py-2 border border-gray-300 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                      >
                        Mégsem
                      </button>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
} 
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';

export default function OrganizationList({ onSelectOrg }) {
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const { currentUser } = useAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredOrganizations, setFilteredOrganizations] = useState([]);

  useEffect(() => {
    async function fetchOrganizations() {
      try {
        const q = query(
          collection(db, 'organizations'),
          where('members', 'array-contains', currentUser.uid)
        );

        const querySnapshot = await getDocs(q);
        const orgList = [];
        querySnapshot.forEach((doc) => {
          orgList.push({ id: doc.id, ...doc.data() });
        });

        setOrganizations(orgList);
      } catch (error) {
        console.error('Error fetching organizations:', error);
        setError('Hiba történt a szervezetek betöltésekor: ' + error.message);
      } finally {
        setLoading(false);
      }
    }

    if (currentUser) {
      fetchOrganizations();
    }
  }, [currentUser]);

  useEffect(() => {
    const filtered = organizations.filter(org => 
      org.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      org.description?.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredOrganizations(filtered);
  }, [organizations, searchTerm]);

  if (loading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
        {error}
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-sm">
      <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-6">
        <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
          <div>
            <h2 className="text-lg font-semibold text-gray-900 flex items-center gap-2">
              <svg className="h-6 w-6 text-indigo-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
              Szervezetek ({filteredOrganizations.length})
            </h2>
            <p className="mt-1 text-sm text-gray-500">Szervezetek kezelése és áttekintése</p>
          </div>

          <div className="flex flex-col sm:flex-row gap-3">
            <div className="relative">
              <input
                type="text"
                placeholder="Szervezet keresése..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 pl-10 pr-4 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                <svg className="h-5 w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                  <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
                </svg>
              </div>
            </div>

            <Link
              to="/organization/new"
              className="inline-flex items-center gap-x-2 rounded-md bg-indigo-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              <svg className="-ml-0.5 h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                <path d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" />
              </svg>
              Új szervezet
            </Link>
          </div>
        </div>
      </div>

      <ul className="divide-y divide-gray-200">
        {filteredOrganizations.map((org, index) => (
          <li key={org.id}>
            <Link 
              to={`/organization/${org.id}`}
              className="group block transform transition-all duration-300 hover:scale-[1.02]"
            >
              <div className={`
                rounded-lg p-4 shadow-sm hover:shadow-lg transition-all duration-300
                animate-gradient bg-[size:200%_200%]
                ${index % 2 === 0 
                  ? 'bg-gradient-to-r from-indigo-600 via-purple-600 to-indigo-600' 
                  : 'bg-gradient-to-r from-blue-600 via-violet-600 to-blue-600'
                }
              `}>
                <div className="flex justify-between items-start">
                  <div className="transform transition-all duration-300 group-hover:translate-x-2">
                    <h3 className="text-xl font-bold text-white flex items-center gap-2">
                      {org.name}
                      <svg 
                        className="h-5 w-5 text-white/70 transition-all duration-300 group-hover:text-white group-hover:translate-x-1" 
                        fill="none" 
                        viewBox="0 0 24 24" 
                        stroke="currentColor"
                      >
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 7l5 5m0 0l-5 5m5-5H6" />
                      </svg>
                    </h3>
                    {org.description && (
                      <p className="mt-2 text-sm text-blue-50 line-clamp-2 transition-opacity duration-300 group-hover:text-white/90">
                        {org.description}
                      </p>
                    )}
                  </div>

                  <div className="flex gap-3">
                    <div className="bg-white/10 backdrop-blur-sm rounded-lg px-3 py-2 transition-all duration-300 hover:bg-white/20">
                      <div className="text-xs text-blue-100">Tagok</div>
                      <div className="text-lg font-bold text-white animate-pulse">
                        {org.members?.length || 0}
                      </div>
                    </div>
                    <div className="bg-white/10 backdrop-blur-sm rounded-lg px-3 py-2 transition-all duration-300 hover:bg-white/20">
                      <div className="text-xs text-blue-100">Ügyfelek</div>
                      <div className="text-lg font-bold text-white animate-pulse">
                        {org.clients?.length || 0}
                      </div>
                    </div>
                  </div>
                </div>
                
                <div className="mt-3 flex flex-wrap items-center gap-3 text-sm text-blue-100 opacity-75 transition-opacity duration-300 group-hover:opacity-100">
                  {org.email && (
                    <span className="inline-flex items-center gap-1.5 transition-transform duration-300 hover:scale-105">
                      <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                      </svg>
                      {org.email}
                    </span>
                  )}
                  {org.createdAt && (
                    <span className="inline-flex items-center gap-1.5 transition-transform duration-300 hover:scale-105">
                      <svg className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z" />
                      </svg>
                      {new Date(org.createdAt).toLocaleDateString()}
                    </span>
                  )}
                </div>
              </div>
            </Link>

            <div className="flex flex-wrap gap-2 pt-3 pb-6 opacity-75 transition-all duration-300 hover:opacity-100">
              <Link 
                to={`/organization/${org.id}/tickets`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-700 bg-blue-50 hover:bg-blue-100 rounded-md transition-colors duration-150 group"
              >
                <svg className="h-4 w-4 mr-1.5 text-blue-500 group-hover:text-blue-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2" />
                </svg>
                Ticketek
              </Link>
              
              <Link 
                to={`/organization/${org.id}/projects`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-purple-700 bg-purple-50 hover:bg-purple-100 rounded-md transition-colors duration-150 group"
              >
                <svg className="h-4 w-4 mr-1.5 text-purple-500 group-hover:text-purple-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M20 7l-8-4-8 4m16 0l-8 4m8-4v10l-8 4m0-10L4 7m8 4v10M4 7v10l8 4" />
                </svg>
                Projektek
              </Link>
              
              <Link 
                to={`/organization/${org.id}/clients`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-green-700 bg-green-50 hover:bg-green-100 rounded-md transition-colors duration-150 group"
              >
                <svg className="h-4 w-4 mr-1.5 text-green-500 group-hover:text-green-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg>
                Ügyfelek
              </Link>
              
              <Link 
                to={`/organization/${org.id}/reports`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-amber-700 bg-amber-50 hover:bg-amber-100 rounded-md transition-colors duration-150 group"
              >
                <svg className="h-4 w-4 mr-1.5 text-amber-500 group-hover:text-amber-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                </svg>
                Kimutatások
              </Link>
              
              <Link 
                to={`/organization/${org.id}/settings`}
                className="inline-flex items-center px-3 py-1.5 text-sm font-medium text-rose-700 bg-rose-50 hover:bg-rose-100 rounded-md transition-colors duration-150 group"
              >
                <svg className="h-4 w-4 mr-1.5 text-rose-500 group-hover:text-rose-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                Beállítások
              </Link>
            </div>
          </li>
        ))}
      </ul>

      {filteredOrganizations.length === 0 && (
        <div className="text-center py-12">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">Nincs találat</h3>
          <p className="mt-1 text-sm text-gray-500">Hozz létre egy új szervezetet vagy módosítsd a keresési feltételeket.</p>
        </div>
      )}

      <style>
        {`
          @keyframes gradient {
            0% { background-position: 0% 50%; }
            50% { background-position: 100% 50%; }
            100% { background-position: 0% 50%; }
          }
          .animate-gradient {
            animation: gradient 15s ease infinite;
          }
        `}
      </style>
    </div>
  );
}
import { collection, doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../config';

const SYSTEM_ADMINS_DOC_ID = 'system_admins';

// Az első rendszergazda UID-je
export const INITIAL_ADMIN_UID = 'FaIRu45Ni7MgU6CAw6Q69srOQ9w2';

export async function initializeSystemSettings() {
  try {
    console.log('Initializing system settings...');
    const settingsRef = doc(db, 'systemSettings', SYSTEM_ADMINS_DOC_ID);
    const settingsDoc = await getDoc(settingsRef);
    
    if (!settingsDoc.exists()) {
      console.log('Creating system_admins document...');
      await setDoc(settingsRef, {
        systemAdmins: [INITIAL_ADMIN_UID],
        createdAt: new Date().toISOString()
      });
      console.log('System admin initialized with UID:', INITIAL_ADMIN_UID);
    } else {
      console.log('System settings already exist');
    }
  } catch (error) {
    console.error('Error initializing system settings:', error);
    throw error;
  }
}

export async function addSystemAdmin(newAdminUid, currentUserUid) {
  const settingsRef = doc(db, 'systemSettings', SYSTEM_ADMINS_DOC_ID);
  const settingsDoc = await getDoc(settingsRef);
  
  if (!settingsDoc.exists() || !settingsDoc.data().systemAdmins.includes(currentUserUid)) {
    throw new Error('Nincs jogosultságod rendszergazdát hozzáadni');
  }

  const currentAdmins = settingsDoc.data().systemAdmins;
  if (currentAdmins.includes(newAdminUid)) {
    throw new Error('Ez a felhasználó már rendszergazda');
  }

  await setDoc(settingsRef, {
    systemAdmins: [...currentAdmins, newAdminUid],
    updatedAt: new Date().toISOString()
  }, { merge: true });
}

export async function isSystemAdmin(uid) {
  const settingsRef = doc(db, 'systemSettings', SYSTEM_ADMINS_DOC_ID);
  const settingsDoc = await getDoc(settingsRef);
  
  if (!settingsDoc.exists()) return false;
  return settingsDoc.data().systemAdmins.includes(uid);
} 
import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import LoadingScreen from '../ui/LoadingScreen';
import TaskList from '../task/TaskList';
import ProjectDocuments from './ProjectDocuments';

// Projekt státuszok és színeik
const PROJECT_STATUSES = {
  active: {
    label: 'Aktív',
    class: 'bg-green-100 text-green-800',
    icon: '🟢'
  },
  on_hold: {
    label: 'Függőben',
    class: 'bg-yellow-100 text-yellow-800',
    icon: '🟡'
  },
  completed: {
    label: 'Lezárt',
    class: 'bg-blue-100 text-blue-800',
    icon: '🔵'
  },
  cancelled: {
    label: 'Megszakítva',
    class: 'bg-red-100 text-red-800',
    icon: '🔴'
  }
};

export default function ProjectDetail() {
  const [project, setProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [updating, setUpdating] = useState(false);
  const { orgId, projectId } = useParams();
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    async function fetchProject() {
      try {
        const projectRef = doc(db, 'projects', projectId);
        const projectSnap = await getDoc(projectRef);
        
        if (!projectSnap.exists()) {
          setError('A projekt nem található');
          return;
        }

        setProject({ id: projectSnap.id, ...projectSnap.data() });
      } catch (err) {
        console.error('Hiba a projekt betöltésekor:', err);
        setError('Hiba történt a projekt betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchProject();
  }, [projectId]);

  useEffect(() => {
    async function fetchOrgName() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    }
    fetchOrgName();
  }, [orgId]);

  const handleStatusChange = async (newStatus) => {
    if (updating || project.status === newStatus) return;
    
    setUpdating(true);
    try {
      const projectRef = doc(db, 'projects', projectId);
      await updateDoc(projectRef, {
        status: newStatus,
        updatedAt: new Date()
      });
      
      setProject(prev => ({
        ...prev,
        status: newStatus,
        updatedAt: new Date()
      }));
    } catch (err) {
      console.error('Hiba a státusz módosításakor:', err);
      setError('Hiba történt a státusz módosításakor');
    } finally {
      setUpdating(false);
    }
  };

  const truncateName = (name, maxLength = 5) => {
    if (!name) return 'Betöltés...';
    return name.length > maxLength ? `${name.slice(0, maxLength)}...` : name;
  };

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center mt-4">{error}</div>;
  if (!project) return null;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
              <svg className="h-3 w-3" fill="currentColor" viewBox="0 0 20 20">
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
            </Link>
          </li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to="/organizations" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Szervezetek</Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">
            <span className="hidden sm:inline">{organizationName || 'Betöltés...'}</span>
            <span className="sm:hidden">{truncateName(organizationName)}</span>
          </Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li><Link to={`/organization/${orgId}/projects`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Projektek</Link></li>
          <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
          <li className="text-gray-500 font-medium">
            <span className="hidden sm:inline">{project?.name || 'Projekt részletek'}</span>
            <span className="sm:hidden">{truncateName(project?.name) || 'Projekt részletek'}</span>
          </li>
        </ol>
      </nav>

      <div className="lg:grid lg:grid-cols-3 lg:gap-8">
        {/* Bal oldali oszlop - Projekt adatok és taskok */}
        <div className="lg:col-span-2">
          {/* Projekt adatok */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    {project?.name}
                  </h3>
                  <p className="mt-1 max-w-2xl text-sm text-gray-500">
                    {project?.description}
                  </p>
                </div>
                <div className="flex space-x-3">
                  <Link
                    to={`/organization/${orgId}/projects/${projectId}/settings`}
                    className="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                  >
                    Szerkesztés
                  </Link>
                </div>
              </div>
            </div>
            <div className="border-t border-gray-200">
              <dl>
                {/* Státusz */}
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Státusz
                  </dt>
                  <dd className="mt-1 text-sm sm:mt-0 sm:col-span-2">
                    <div className="flex items-center space-x-2">
                      {/* Státusz gombok */}
                      {Object.entries(PROJECT_STATUSES).map(([status, info]) => (
                        <button
                          key={status}
                          onClick={() => handleStatusChange(status)}
                          disabled={updating || project.status === status}
                          className={`inline-flex items-center px-3 py-1 rounded-full text-sm font-medium 
                            ${project.status === status 
                              ? info.class
                              : 'bg-gray-100 text-gray-800 hover:bg-gray-200'
                            } transition-colors duration-200 ${updating ? 'opacity-50 cursor-not-allowed' : ''}`}
                        >
                          <span className="mr-1">{info.icon}</span>
                          {info.label}
                        </button>
                      ))}
                    </div>
                  </dd>
                </div>

                {/* Létrehozva */}
                <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Létrehozva
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {project.createdAt?.toDate().toLocaleDateString()}
                  </dd>
                </div>

                {/* Határidő */}
                <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">
                    Határidő
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                    {project.deadline ? (
                      <>
                        {project.deadline.toDate().toLocaleDateString()}
                        {/* Határidő közelségének jelzése */}
                        {project.deadline.toDate() < new Date() ? (
                          <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-red-100 text-red-800">
                            Lejárt
                          </span>
                        ) : (
                          project.deadline.toDate() - new Date() < 7 * 24 * 60 * 60 * 1000 && (
                            <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-yellow-100 text-yellow-800">
                              Közelgő határidő
                            </span>
                          )
                        )}
                      </>
                    ) : (
                      'Nincs megadva'
                    )}
                  </dd>
                </div>
              </dl>
            </div>
          </div>

          {/* Taskok */}
          <div className="bg-white shadow overflow-hidden sm:rounded-lg mb-6">
            <div className="px-4 py-5 sm:px-6">
              <div className="flex justify-between items-center">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Taskok
                </h3>
                <Link
                  to={`/organization/${orgId}/projects/${projectId}/tasks/new`}
                  className="inline-flex items-center px-3 py-1.5 border border-transparent text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  + Új task
                </Link>
              </div>
            </div>
            <TaskList projectId={projectId} />
          </div>
        </div>

        {/* Jobb oldali oszlop - Dokumentumok */}
        <div className="lg:col-span-1">
          <ProjectDocuments />
        </div>
      </div>
    </div>
  );
} 
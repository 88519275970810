import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import TaskList from './TaskList';

export default function TaskListPage() {
  const { orgId, projectId } = useParams();
  const [projectName, setProjectName] = useState('');
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    const fetchProjectAndOrg = async () => {
      try {
        const projectDoc = await getDoc(doc(db, 'projects', projectId));
        if (projectDoc.exists()) {
          setProjectName(projectDoc.data().name);

          const orgDoc = await getDoc(doc(db, 'organizations', orgId));
          if (orgDoc.exists()) {
            setOrganizationName(orgDoc.data().name);
          }
        }
      } catch (error) {
        console.error('Hiba:', error);
      }
    };

    fetchProjectAndOrg();
  }, [orgId, projectId]);

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8">
        <ol className="flex items-center space-x-2 text-sm text-gray-500">
          <li>
            <Link to="/organizations" className="hover:text-gray-700">Szervezetek</Link>
          </li>
          <li>/</li>
          <li>
            <Link to={`/organization/${orgId}`} className="hover:text-gray-700">
              {organizationName}
            </Link>
          </li>
          <li>/</li>
          <li>
            <Link to={`/organization/${orgId}/projects`} className="hover:text-gray-700">
              Projektek
            </Link>
          </li>
          <li>/</li>
          <li>
            <Link to={`/organization/${orgId}/projects/${projectId}`} className="hover:text-gray-700">
              {projectName}
            </Link>
          </li>
          <li>/</li>
          <li className="text-gray-900 font-medium">Taskok</li>
        </ol>
      </nav>

      {/* Header */}
      <div className="mb-6 flex justify-between items-center">
        <h1 className="text-2xl font-bold text-gray-900">
          {projectName} - Taskok
        </h1>
        <Link
          to={`/organization/${orgId}/projects/${projectId}/tasks/new`}
          className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
        >
          + Új task
        </Link>
      </div>

      {/* Task List */}
      <TaskList projectId={projectId} />
    </div>
  );
} 
import { Link } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Avatar from '../common/Avatar';
import { useState, useEffect } from 'react';
import NotificationDropdown from '../notifications/NotificationDropdown';
import Snowfall from './Snowfall';

export default function Navbar() {
  const { currentUser, signOut } = useAuth();
  const [spinnerChar, setSpinnerChar] = useState('|');
  const [showQuote, setShowQuote] = useState(false);
  const [currentQuote, setCurrentQuote] = useState(null);
  const [isDarkMode, setIsDarkMode] = useState(true);

  // Példa idézetek (később jöhet adatbázisból)
  const quotes = [
    { author: 'Nagy Roland', quote: 'Minden bug egy új lehetőség' },
    { author: 'Kecskés Péter', quote: 'All systems are available' },
  ];

  // Spinner animáció
  useEffect(() => {
    const chars = ['|', '/', '─', '\\'];
    let index = 0;
    
    const interval = setInterval(() => {
      index = (index + 1) % chars.length;
      setSpinnerChar(chars[index]);
    }, 250);

    return () => clearInterval(interval);
  }, []);

  // Idézet megjelenítő animáció - csak asztali nézetben
  useEffect(() => {
    const isMobile = window.innerWidth < 768; // md breakpoint
    if (!currentUser || isMobile) return;

    const showNextQuote = () => {
      const randomQuote = quotes[Math.floor(Math.random() * quotes.length)];
      setCurrentQuote(randomQuote);
      setShowQuote(true);

      setTimeout(() => {
        setShowQuote(false);
        setTimeout(() => {
          setCurrentQuote(null);
        }, 500);
      }, 4000);
    };

    const interval = setInterval(showNextQuote, 8000);
    showNextQuote();

    return () => clearInterval(interval);
  }, [currentUser]);

  // Téma váltás kezelése - első betöltéskor is fusson le
  useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
    } else {
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  // Első betöltéskor állítsuk be a dark mode-ot
  useEffect(() => {
    document.documentElement.classList.add('dark');
  }, []);

  return (
    <nav className="bg-white dark:bg-gray-950 shadow relative">
      <div className="relative">
        <Snowfall />
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              {/* Logo és cím */}
              <div className="flex-shrink-0 flex items-center">
                <Link to={currentUser ? "/" : "/login"} className="flex items-center space-x-2">
                  <svg
                    className="h-8 w-8 sm:h-12 sm:w-12 text-indigo-600 dark:text-indigo-400"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7 3C5.34315 3 4 4.34315 4 6V18C4 19.6569 5.34315 21 7 21H17C18.6569 21 20 19.6569 20 18V8.82843C20 8.29799 19.7893 7.78929 19.4142 7.41421L15.5858 3.58579C15.2107 3.21071 14.702 3 14.1716 3H7Z"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                    <path
                      d="M15 4V8H19"
                      stroke="currentColor"
                      strokeWidth="2"
                    />
                    <path
                      d="M8 12H16"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                    <path
                      d="M8 16H13"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                    />
                  </svg>
                  <div className="flex items-center">
                    <span className="text-lg sm:text-xl font-bold text-indigo-600 dark:text-indigo-400">YouDesk</span>
                    <div className="flex items-center space-x-2">
                      <span className="font-mono text-indigo-500 dark:text-indigo-300 animate-pulse ml-2">
                        {spinnerChar}
                      </span>
                      <span className="ml-2 inline-flex items-center px-2 py-0.5 rounded text-xs font-medium bg-yellow-100 text-yellow-800 border border-yellow-200">
                        BETA
                      </span>
                    </div>
                  </div>
                </Link>
              </div>

              {/* Idézet megjelenítő - csak asztali nézetben */}
              {currentUser && currentQuote && (
                <div className="hidden md:flex ml-6 items-center transition-all duration-500 ease-in-out
                  ${showQuote ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-4'}">
                  <div className="flex items-center space-x-2 bg-gray-50 dark:bg-gray-800 px-4 py-2 rounded-lg">
                    <div className="flex flex-col">
                      <p className="text-sm text-gray-600 dark:text-gray-300 italic">"{currentQuote.quote}"</p>
                      <span className="text-xs text-gray-500 dark:text-gray-400 mt-0.5">— {currentQuote.author}</span>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* Felhasználói menü */}
            {currentUser && (
              <div className="flex items-center">
                <div className="ml-3 relative">
                  <div className="flex items-center space-x-4">
                    <NotificationDropdown />
                    <Link to="/profile" className="flex items-center space-x-3 hover:opacity-80">
                      <div className="animate-spin-slow">
                        <Avatar user={currentUser} size="sm" />
                      </div>
                      <span className="hidden md:block text-sm font-medium text-gray-700 dark:text-gray-300">
                        {currentUser.displayName || currentUser.email}
                      </span>
                    </Link>
                    <button
                      onClick={signOut}
                      className="text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none"
                    >
                      <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                      </svg>
                    </button>
                    <button
                      onClick={() => setIsDarkMode(!isDarkMode)}
                      className="text-sm font-medium text-gray-700 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white focus:outline-none"
                    >
                      {isDarkMode ? (
                        <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 3v1m0 16v1m8-9h1M4 12H3m15.364-6.364l.707-.707M6.343 17.657l-.707.707m12.728 0l.707-.707M6.343 6.343l-.707-.707M16 12a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      ) : (
                        <svg className="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12.79A9 9 0 1111.21 3a7 7 0 109.79 9.79z" />
                        </svg>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      
      {/* Béta környezet figyelmeztetés */}
      <div className="bg-yellow-50 dark:bg-yellow-950 border-b border-yellow-100 dark:border-yellow-900">
        <div className="max-w-7xl mx-auto py-1 px-4 sm:px-6 lg:px-8">
          <div className="flex items-center justify-center text-xs text-yellow-800 dark:text-yellow-200">
            <svg className="h-4 w-4 mr-1.5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
            </svg>
            <span className="hidden sm:inline">Ez egy béta környezet. Az adatok és funkciók tesztelés alatt állnak.</span>
            <span className="sm:hidden">Béta verzió</span>
          </div>
        </div>
      </div>
    </nav>
  );
} 
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getFunctions } from 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyB7zaNnqRLpyxuHVyqUcDdoD8vgOjGtCyk",
  authDomain: "youdesk-b6b98.firebaseapp.com",
  projectId: "youdesk-b6b98",
  storageBucket: "youdesk-b6b98.firebasestorage.app",
  messagingSenderId: "745510548045",
  appId: "1:745510548045:web:9380ab12508daaabb0e204",
  measurementId: "G-CLMHXFRBWJ"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const storage = getStorage(app);
export const functions = getFunctions(app);
 
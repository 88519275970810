import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { collection, query, where, getDocs, orderBy, doc, getDoc } from 'firebase/firestore';
import { format } from 'date-fns';
import { hu } from 'date-fns/locale';
import LoadingSpinner from '../ui/LoadingSpinner';

function getPriorityStyle(priority) {
  switch (priority) {
    case 'urgent':
      return 'bg-red-50 hover:bg-red-100 border-l-4 border-red-500';
    case 'high':
      return 'bg-orange-50 hover:bg-orange-100 border-l-4 border-orange-500';
    case 'normal':
      return 'bg-white hover:bg-gray-50 border-l-4 border-blue-500';
    case 'low':
      return 'bg-gray-50 hover:bg-gray-100 border-l-4 border-gray-500';
    default:
      return 'bg-white hover:bg-gray-50';
  }
}

function formatDate(timestamp) {
  if (!timestamp) return 'Nincs dátum';
  
  const options = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: '2-digit',
    minute: '2-digit'
  };
  
  try {
    const date = timestamp.seconds ? 
      new Date(timestamp.seconds * 1000) : 
      new Date(timestamp);
      
    return date.toLocaleDateString('hu-HU', options);
  } catch (error) {
    console.error('Dátum formázási hiba:', error);
    return 'Érvénytelen dátum';
  }
}

export default function TicketList() {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const [tickets, setTickets] = useState([]);
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  // Új state-ek a szűréshez
  const [filters, setFilters] = useState({
    status: 'unresolved',
    priority: '',
    searchTerm: '',
    clientId: '',
    dateFrom: '',
    dateTo: '',
    month: '' // YYYY-MM formátumban
  });

  // Szűrők kezelése
  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters(prev => ({
      ...prev,
      [name]: value
    }));
  };

  // Szűrt ticketek
  const filteredTickets = tickets.filter(ticket => {
    const matchesStatus = !filters.status ? true : 
      filters.status === 'unresolved' ? 
        !['closed'].includes(ticket.status) : // Minden ami nem lezárt
        ticket.status === filters.status;
    
    const matchesPriority = !filters.priority || ticket.priority === filters.priority;
    const matchesClient = !filters.clientId || ticket.clientId === filters.clientId;
    const matchesSearch = !filters.searchTerm || 
      ticket.title.toLowerCase().includes(filters.searchTerm.toLowerCase());
    
    // Dátum szűrés
    let matchesDate = true;
    const ticketDate = new Date(ticket.createdAt);

    if (filters.month) {
      const [year, month] = filters.month.split('-');
      matchesDate = ticketDate.getFullYear() === parseInt(year) && 
                   ticketDate.getMonth() === parseInt(month) - 1;
    } else {
      if (filters.dateFrom) {
        matchesDate = matchesDate && ticketDate >= new Date(filters.dateFrom);
      }
      if (filters.dateTo) {
        matchesDate = matchesDate && ticketDate <= new Date(filters.dateTo);
      }
    }
    
    return matchesStatus && matchesPriority && matchesClient && matchesSearch && matchesDate;
  });

  // Először betöltjük a szervezet adatait
  useEffect(() => {
    async function fetchOrganization() {
      try {
        console.log('Fetching organization:', orgId); // Debug log
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          console.log('Organization data:', orgData); // Debug log
          setOrganization(orgData);
        } else {
          console.log('Organization not found'); // Debug log
          setError('Szervezet nem található');
        }
      } catch (err) {
        console.error('Error fetching organization:', err); // Debug log
        setError('Hiba történt a szervezet betöltésekor');
      }
    }

    if (orgId) {
      fetchOrganization();
    }
  }, [orgId]);

  // Majd betöltjük a ticketeket
  useEffect(() => {
    async function fetchTickets() {
      try {
        console.log('Fetching tickets for org:', orgId); // Debug log
        const q = query(
          collection(db, 'tickets'),
          where('organizationId', '==', orgId),
          orderBy('createdAt', 'desc')
        );
        const querySnapshot = await getDocs(q);
        const ticketList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        console.log('Tickets loaded:', ticketList); // Debug log
        setTickets(ticketList);
      } catch (err) {
        console.error('Error fetching tickets:', err); // Debug log
        setError('Hiba történt a ticketek betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    if (orgId) {
      fetchTickets();
    }
  }, [orgId]);

  // Módosítsuk a calculateClientStats függvényt
  const calculateClientStats = (clientId) => {
    if (!clientId) return { totalTime: 0, totalCost: 0, totalVisits: 0 };
    
    const clientTickets = filteredTickets.filter(ticket => ticket.clientId === clientId);
    
    return clientTickets.reduce((acc, ticket) => {
      // Munkaidő számítás
      const workTimes = ticket.workTimes || [];
      workTimes.forEach(wt => {
        const workDate = new Date(wt.createdAt);
        let includeWorkTime = true;
        
        if (filters.month) {
          const [year, month] = filters.month.split('-');
          includeWorkTime = workDate.getFullYear() === parseInt(year) && 
                           workDate.getMonth() === parseInt(month) - 1;
        } else {
          if (filters.dateFrom) {
            includeWorkTime = includeWorkTime && workDate >= new Date(filters.dateFrom);
          }
          if (filters.dateTo) {
            includeWorkTime = includeWorkTime && workDate <= new Date(filters.dateTo);
          }
        }

        if (includeWorkTime) {
          acc.totalTime += wt.minutes;
          const client = organization?.clients?.find(c => c.id === clientId);
          if (client?.hourlyRate) {
            acc.totalCost += (wt.minutes / 60) * client.hourlyRate;
          }
        }
      });

      // Kiszállások számítása
      const visits = ticket.visits || [];
      visits.forEach(visit => {
        const visitDate = new Date(visit.date);
        let includeVisit = true;

        if (filters.month) {
          const [year, month] = filters.month.split('-');
          includeVisit = visitDate.getFullYear() === parseInt(year) && 
                        visitDate.getMonth() === parseInt(month) - 1;
        } else {
          if (filters.dateFrom) {
            includeVisit = includeVisit && visitDate >= new Date(filters.dateFrom);
          }
          if (filters.dateTo) {
            includeVisit = includeVisit && visitDate <= new Date(filters.dateTo);
          }
        }

        if (includeVisit) {
          acc.totalVisits += 1;
          const client = organization?.clients?.find(c => c.id === clientId);
          if (client?.visitRate) {
            acc.totalCost += client.visitRate;
          }
        }
      });
      
      return acc;
    }, { totalTime: 0, totalCost: 0, totalVisits: 0 });
  };

  const handleTicketClick = (ticketId) => {
    navigate(`/organization/${orgId}/tickets/${ticketId}`);
  };

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-[400px]">
        <LoadingSpinner size="large" color="indigo" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="text-red-500">
          {error}
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Szűrők */}
      <div className="bg-white p-4 rounded-lg shadow">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Státusz</label>
            <select
              name="status"
              value={filters.status}
              onChange={handleFilterChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
            >
              <option value="">Összes</option>
              <option value="unresolved">Megoldatlanok</option>
              <option value="new">Új</option>
              <option value="in_progress">Folyamatban</option>
              <option value="resolved">Megoldva</option>
              <option value="closed">Lezárva</option>
              <option value="waiting_for_client">Ügyfél válaszára vár</option>
              <option value="waiting_for_third_party">Harmadik fél válaszára vár</option>
              <option value="collector">Gyűjtő ticket</option>
            </select>
          </div>
          
          {/* További szűrők hasonló struktúrával */}
        </div>

        <div className="mt-4">
          <input
            type="text"
            name="searchTerm"
            placeholder="Keresés..."
            value={filters.searchTerm}
            onChange={handleFilterChange}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>
      </div>

      {/* Ticket lista */}
      <div className="bg-white rounded-lg shadow overflow-hidden">
        <div className="sm:hidden">
          {/* Mobil nézet */}
          <div className="divide-y divide-gray-200">
            {filteredTickets.map((ticket) => (
              <div 
                key={ticket.id}
                onClick={() => handleTicketClick(ticket.id)}
                className={`p-4 cursor-pointer ${getPriorityStyle(ticket.priority)}`}
              >
                <div className="flex justify-between items-start mb-2">
                  <span className="text-xs font-mono text-gray-500">#{ticket.id.substring(0, 8)}</span>
                  <div className="flex space-x-2">
                    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(ticket.status)}`}>
                      {getStatusText(ticket.status)}
                    </span>
                    <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getPriorityBadgeColor(ticket.priority)}`}>
                      {getPriorityText(ticket.priority)}
                    </span>
                  </div>
                </div>
                
                <h3 className="text-sm font-medium text-gray-900 mb-2">{ticket.title}</h3>
                
                <div className="flex flex-wrap gap-2 text-xs text-gray-500">
                  <span>Munkaidő: {(() => {
                    const totalMinutes = (ticket.workTimes || []).reduce((acc, wt) => acc + wt.minutes, 0);
                    const hours = Math.floor(totalMinutes / 60);
                    const minutes = totalMinutes % 60;
                    return `${hours}ó ${minutes}p`;
                  })()}</span>
                  <span>Kiszállások: {(ticket.visits || []).length} db</span>
                  <span>Létrehozva: {formatDate(ticket.createdAt)}</span>
                </div>
              </div>
            ))}
          </div>
        </div>

        {/* Asztali nézet */}
        <div className="hidden sm:block">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Azonosító
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tárgy
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Ügyfél
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Bejelentő
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Státusz
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Prioritás
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Munkaidő
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Kiszállások
                  </th>
                  <th scope="col" className="px-3 py-2 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Létrehozva
                  </th>
                  <th scope="col" className="relative px-3 py-2">
                    <span className="sr-only">Műveletek</span>
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {filteredTickets.map((ticket) => (
                  <tr 
                    key={ticket.id}
                    className="hover:bg-gray-50 cursor-pointer"
                    onClick={() => handleTicketClick(ticket.id)}
                  >
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm font-mono text-gray-500">
                      #{ticket.id.substring(0, 8)}
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm text-gray-900">
                      {ticket.title}
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm text-gray-500">
                      {organization?.clients?.find(c => c.id === ticket.clientId)?.name || 'Ismeretlen'}
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm text-gray-500">
                      {ticket.contact?.name || 'Nincs megadva'}
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm">
                      <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getStatusBadgeColor(ticket.status)}`}>
                        {getStatusText(ticket.status)}
                      </span>
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm">
                      <span className={`inline-flex items-center px-2 py-0.5 rounded-full text-xs font-medium ${getPriorityBadgeColor(ticket.priority)}`}>
                        {getPriorityText(ticket.priority)}
                      </span>
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm text-gray-500">
                      {(() => {
                        const totalMinutes = (ticket.workTimes || []).reduce((acc, wt) => acc + wt.minutes, 0);
                        const hours = Math.floor(totalMinutes / 60);
                        const minutes = totalMinutes % 60;
                        return `${hours}ó ${minutes}p`;
                      })()}
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm text-gray-500">
                      {(ticket.visits || []).length} db
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(ticket.createdAt)}
                    </td>
                    <td className="px-3 py-1.5 whitespace-nowrap text-right text-sm font-medium">
                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleTicketClick(ticket.id);
                        }}
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Részletek
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

// Segédfüggvnyek a státusz s prioritás megjelenítéséhez
function getStatusBadgeColor(status) {
  switch (status) {
    case 'new': return 'bg-blue-100 text-blue-800';
    case 'in_progress': return 'bg-yellow-100 text-yellow-800';
    case 'resolved': return 'bg-green-100 text-green-800';
    case 'closed': return 'bg-gray-100 text-gray-800';
    case 'waiting_for_client': return 'bg-purple-100 text-purple-800';
    case 'waiting_for_third_party': return 'bg-indigo-100 text-indigo-800';
    case 'collector': return 'bg-emerald-100 text-emerald-800';
    default: return 'bg-gray-100 text-gray-800';
  }
}

function getStatusText(status) {
  switch (status) {
    case 'new': return 'Új';
    case 'in_progress': return 'Folyamatban';
    case 'resolved': return 'Megoldva';
    case 'closed': return 'Lezárva';
    case 'waiting_for_client': return 'Ügyfél válaszára vár';
    case 'waiting_for_third_party': return 'Harmadik fél válaszára vár';
    case 'collector': return 'Gyűjtő ticket'; 
    default: return status;
  }
}

function getPriorityText(priority) {
  switch (priority) {
    case 'low': return 'Alacsony';
    case 'normal': return 'Normál';
    case 'high': return 'Magas';
    case 'urgent': return 'Sürgős';
    default: return priority;
  }
}

// Prioritás badge színek
function getPriorityBadgeColor(priority) {
  switch (priority) {
    case 'urgent':
      return 'bg-red-100 text-red-800';
    case 'high':
      return 'bg-orange-100 text-orange-800';
    case 'normal':
      return 'bg-blue-100 text-blue-800';
    case 'low':
      return 'bg-gray-100 text-gray-800';
    default:
      return 'bg-gray-100 text-gray-800';

  }
} 
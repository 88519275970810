export default function Avatar({ user, size = 'md' }) {
  const sizes = {
    sm: 'h-6 w-6',
    md: 'h-8 w-8',
    lg: 'h-10 w-10',
    xl: 'h-12 w-12'
  };

  const textSizes = {
    sm: 'text-xs',
    md: 'text-sm',
    lg: 'text-base',
    xl: 'text-lg'
  };

  return (
    <div className={`flex-shrink-0 ${sizes[size]} rounded-full overflow-hidden`}>
      {user?.photoURL ? (
        <img
          className="h-full w-full object-cover"
          src={user.photoURL}
          alt={user.displayName || user.email}
        />
      ) : (
        <div className={`h-full w-full bg-gray-200 flex items-center justify-center`}>
          <span className={`${textSizes[size]} font-medium text-gray-500`}>
            {(user?.displayName || user?.email || '?').charAt(0).toUpperCase()}
          </span>
        </div>
      )}
    </div>
  );
} 
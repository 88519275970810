import { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { db } from '../../firebase/config';
import { initializeSystemSettings } from '../../firebase/collections/systemSettings';

export default function InitializeAdmin() {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    async function checkInitialization() {
      try {
        const settingsDoc = await getDoc(doc(db, 'systemSettings', 'system_admins'));
        if (settingsDoc.exists() && settingsDoc.data().systemAdmins?.length > 0) {
          setIsInitialized(true);
        }
      } catch (err) {
        console.error('Error checking system admin status:', err);
      }
    }
    
    checkInitialization();
  }, []);

  const handleInitialize = async () => {
    setLoading(true);
    setError('');
    setSuccess('');

    try {
      await initializeSystemSettings();
      setSuccess('Rendszeradmin sikeresen inicializálva!');
      setIsInitialized(true);
    } catch (err) {
      setError('Hiba történt: ' + err.message);
    } finally {
      setLoading(false);
    }
  };

  if (isInitialized) {
    return null;
  }

  return (
    <div className="p-4">
      {error && <div className="text-red-600 mb-4">{error}</div>}
      {success && <div className="text-green-600 mb-4">{success}</div>}
      <button
        onClick={handleInitialize}
        disabled={loading}
        className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
      >
        {loading ? 'Folyamatban...' : 'Rendszeradmin inicializálása'}
      </button>
    </div>
  );
} 
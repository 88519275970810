import React, { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { 
  collection, 
  query, 
  where, 
  getDocs,
  doc,
  getDoc,
  updateDoc
} from 'firebase/firestore';
import LoadingSpinner from '../ui/LoadingSpinner';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Select from 'react-select';

export default function Reports() {
  const { orgId } = useParams();
  const [loading, setLoading] = useState(true);
  const [setError] = useState(null);
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState('all');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [tickets, setTickets] = useState([]);
  const [taskStats, setTaskStats] = useState({
    total: 0,
    new: 0,
    inProgress: 0,
    completed: 0,
    totalHours: 0,
    totalVisits: 0
  });
  const [updating, setUpdating] = useState(false);
  const [organizationName, setOrganizationName] = useState('');

  // Generate months array for select
  const months = [
    { value: 0, label: 'Január' },
    { value: 1, label: 'Február' },
    { value: 2, label: 'Március' },
    { value: 3, label: 'Április' },
    { value: 4, label: 'Május' },
    { value: 5, label: 'Június' },
    { value: 6, label: 'Július' },
    { value: 7, label: 'Augusztus' },
    { value: 8, label: 'Szeptember' },
    { value: 9, label: 'Október' },
    { value: 10, label: 'November' },
    { value: 11, label: 'December' }
  ];

  // Generate years array (last 5 years)
  const years = Array.from({ length: 5 }, (_, i) => new Date().getFullYear() - i);

  // Add this helper function at the top of the component
  const formatDate = (date) => {
    if (!date) return 'Nincs dátum';
    
    try {
      // Handle Firestore Timestamp
      const dateObj = date?.toDate ? date.toDate() : new Date(date);
      
      return dateObj.toLocaleDateString('hu-HU', {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit'
      });
    } catch (error) {
      console.error('Date formatting error:', error);
      return 'Érvénytelen dátum';
    }
  };

  // Fetch clients
  useEffect(() => {
    const fetchClients = async () => {
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          setClients(orgDoc.data().clients || []);
        }
      } catch (error) {
        console.error('Hiba a kliensek betöltésekor:', error);
        setError('Hiba történt a kliensek betöltésekor');
      }
    };

    fetchClients();
  }, [orgId]);

  // Fetch tickets and calculate stats
  useEffect(() => {
    const fetchTickets = async () => {
      if (!orgId) return;

      try {
        const ticketsRef = collection(db, 'tickets');
        let baseQuery = [where('organizationId', '==', orgId)];

        if (selectedClient !== 'all') {
          baseQuery.push(where('clientId', '==', selectedClient));
        }

        // Create Firestore timestamps for the query
        const startDate = new Date(selectedYear, selectedMonth, 1);
        const endDate = new Date(selectedYear, selectedMonth + 1, 0, 23, 59, 59);

        baseQuery.push(
          where('createdAt', '>=', startDate),
          where('createdAt', '<=', endDate)
        );

        const q = query(ticketsRef, ...baseQuery);
        const querySnapshot = await getDocs(q);
        
        const fetchedTickets = querySnapshot.docs.map(doc => {
          const data = doc.data();
          return {
            id: doc.id,
            ...data,
            createdAt: data.createdAt?.toDate?.() || data.createdAt
          };
        });

        console.log('Date range:', { 
          startDate: startDate.toISOString(), 
          endDate: endDate.toISOString() 
        });
        console.log('Fetched tickets:', fetchedTickets);

        setTickets(fetchedTickets);
        
        const stats = {
          total: fetchedTickets.length,
          new: 0,
          inProgress: 0,
          completed: 0,
          totalHours: 0,
          totalVisits: 0
        };

        fetchedTickets.forEach(ticket => {
          switch (ticket.status) {
            case 'new':
              stats.new++;
              break;
            case 'in_progress':
              stats.inProgress++;
              break;
            case 'resolved':
            case 'closed':
              stats.completed++;
              break;
            default:
              break;
          }

          if (ticket.workTimes && Array.isArray(ticket.workTimes)) {
            const totalMinutes = ticket.workTimes.reduce((sum, workTime) => {
              return sum + (workTime.minutes || 0);
            }, 0);
            stats.totalHours += totalMinutes / 60;
          }

          if (ticket.visits && Array.isArray(ticket.visits)) {
            stats.totalVisits += ticket.visits.length;
          }
        });

        setTaskStats(stats);
        setLoading(false);
      } catch (error) {
        console.error('Hiba a ticketek betöltésekor:', error);
        setError('Hiba történt a ticketek betöltésekor');
        setLoading(false);
      }
    };

    fetchTickets();
  }, [orgId, selectedClient, selectedMonth, selectedYear]);

  // Helper function a munkaórák számolásához
  const calculateHours = (workTimes) => {
    if (!workTimes || !Array.isArray(workTimes)) return 0;
    const totalMinutes = workTimes.reduce((sum, workTime) => sum + (workTime.minutes || 0), 0);
    return (totalMinutes / 60).toFixed(1);
  };

  // Státusz módosító függvény
  const handleStatusChange = async (ticketId, newStatus) => {
    try {
      setUpdating(true);
      const ticketRef = doc(db, 'tickets', ticketId);
      await updateDoc(ticketRef, {
        status: newStatus,
        updatedAt: new Date().toISOString()
      });

      // Frissítjük a helyi state-et
      setTickets(tickets.map(ticket => 
        ticket.id === ticketId 
          ? { ...ticket, status: newStatus }
          : ticket
      ));

      // Frissítjük a statisztikákat
      const newStats = { ...taskStats };
      const oldTicket = tickets.find(t => t.id === ticketId);
      
      // Csökkentjük a régi státusz számát
      if (oldTicket.status === 'new') newStats.new--;
      else if (oldTicket.status === 'in_progress') newStats.inProgress--;
      else if (['resolved', 'closed'].includes(oldTicket.status)) newStats.completed--;

      // Növeljük az új státusz számát
      if (newStatus === 'new') newStats.new++;
      else if (newStatus === 'in_progress') newStats.inProgress++;
      else if (['resolved', 'closed'].includes(newStatus)) newStats.completed++;

      setTaskStats(newStats);
    } catch (error) {
      console.error('Hiba a státusz módosításakor:', error);
      alert('Hiba történt a státusz módosításakor');
    } finally {
      setUpdating(false);
    }
  };

  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a3'
    });
    
    // Helper function for UTF-8 encoding
    const encodeHungarianChars = (text) => {
      return text
        .replace(/ő/g, 'ö')
        .replace(/Ő/g, 'Ö')
        .replace(/ű/g, 'ü')
        .replace(/Ű/g, 'Ü');
    };
    
    doc.setFont('helvetica', 'normal');
    doc.setLanguage("hu");
    
    doc.setFontSize(18);
    doc.setFont('helvetica', 'bold');
    doc.text(encodeHungarianChars('Havi riport'), 14, 35);

    // Szűrési feltételek
    doc.setFontSize(12);
    doc.setFont('helvetica', 'normal');
    doc.text(encodeHungarianChars(`Dátum: ${months[selectedMonth].label} ${selectedYear}`), 14, 45);
    doc.text(encodeHungarianChars(`Ügyfél: ${selectedClient === 'all' ? 'Összes' : 
      clients.find(c => c.id === selectedClient)?.name || 'Ismeretlen'}`), 14, 53);

    // Statisztikák táblázata
    doc.autoTable({
      startY: 63,
      head: [['Statisztikák', 'Érték']],
      body: [
        ['Összes bejelentés', taskStats.total.toString()],
        ['Új bejelentések', taskStats.new.toString()],
        ['Folyamatban', taskStats.inProgress.toString()],
        ['Lezárt bejelentések', taskStats.completed.toString()],
        ['Összes munkaóra', `${taskStats.totalHours.toFixed(1)} óra`],
        ['Összes kiszállás', `${taskStats.totalVisits} db`]
      ],
      theme: 'grid',
      headStyles: { 
        fillColor: [66, 66, 66],
        fontStyle: 'bold'
      },
      styles: { 
        fontSize: 10,
        font: 'helvetica'
      }
    });

    // Bejelentések táblázata
    const ticketRows = tickets.map(ticket => [
      ticket.id.slice(0, 8),
      ticket.title,
      ticket.contact?.name || 'Nincs megadva',
      ticket.status === 'new' ? 'Új' :
      ticket.status === 'in_progress' ? 'Folyamatban' :
      ticket.status === 'resolved' ? 'Megoldva' :
      ticket.status === 'closed' ? 'Lezárva' :
      ticket.status === 'waiting_for_client' ? 'Ügyfél válaszára vár' :
      ticket.status === 'waiting_for_third_party' ? 'Harmadik fél válaszára vár' :
      ticket.status === 'collector' ? 'Gyűjtő ticket' : 'Státusz nincs beállítva',
      `${calculateHours(ticket.workTimes)} óra`,
      `${(ticket.visits || []).length} db\n${ticket.visits?.length ? 
        ticket.visits.map(visit => formatDate(visit.date)).join('\n') : ''}`,
      formatDate(ticket.createdAt)
    ]);

    // Összesítő sor módosítása
    ticketRows.push([
      { content: 'Összesen:', colSpan: 2, styles: { fontStyle: 'bold' } },
      { content: '', styles: { fontStyle: 'bold' } },
      { content: '', styles: { fontStyle: 'bold' } },
      { content: `${taskStats.totalHours.toFixed(1)} óra`, styles: { fontStyle: 'bold' } },
      { content: `${taskStats.totalVisits} db`, styles: { fontStyle: 'bold' } },
      { content: '', styles: { fontStyle: 'bold' } }
    ]);

    doc.autoTable({
      startY: doc.previousAutoTable.finalY + 15,
      head: [['Azonosító', 'Tárgy', 'Bejelentő', 'Státusz', 'Munkaóra', 'Kiszállás', 'Létrehozva']],
      body: ticketRows,
      theme: 'grid',
      headStyles: { 
        fillColor: [66, 66, 66],
        fontStyle: 'bold'
      },
      styles: { 
        fontSize: 10,
        font: 'helvetica',
        cellPadding: 2
      },
      columnStyles: {
        0: { cellWidth: 30 },
        1: { cellWidth: 'auto' },
        2: { cellWidth: 40 },
        3: { cellWidth: 35 },
        4: { cellWidth: 30 },
        5: { cellWidth: 45 },
        6: { cellWidth: 35 }
      }
    });

    // Lábléc
    const pageHeight = doc.internal.pageSize.height;
    const currentYear = new Date().getFullYear();
    
    // Vonal a lábléc előtt
    doc.setDrawColor(200, 200, 200);
    doc.line(14, pageHeight - 30, 196, pageHeight - 30);

    // Lábléc szöveg
    doc.setFont('helvetica', 'normal');
    doc.setFontSize(8);
    doc.setTextColor(100, 100, 100);

    // Első sor
    doc.text('A report aláírás és pecsét nélkül is érvényes', 14, pageHeight - 25);
    
    // Második sor - bal oldalon a WebForYou Kft. és dátum, jobb oldalon a YouDesk és év
    const currentDate = new Date().toLocaleDateString('hu-HU', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
    
    doc.text(`Webforyou Kft. (${currentDate})`, 14, pageHeight - 15);
    doc.text(`YouDesk © ${currentYear}`, 196, pageHeight - 15, { align: 'right' });

    // PDF fájl mentése
    const fileName = `youdesk_${selectedClient === 'all' ? 'osszes' : clients.find(c => c.id === selectedClient)?.name}_${selectedYear}_${(selectedMonth + 1).toString().padStart(2, '0')}.pdf`;
    doc.save(fileName);
  };

  // Opciók formázása a react-select számára
  const clientOptions = [
    { value: 'all', label: 'Összes ügyfél' },
    ...clients.map(client => ({
      value: client.id,
      label: client.name
    }))
  ];

  // Select értékének kezelése
  const selectedClientOption = clientOptions.find(
    option => option.value === selectedClient
  ) || clientOptions[0];

  // Szervezet nevének lekérése
  useEffect(() => {
    const fetchOrgName = async () => {
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          setOrganizationName(orgDoc.data().name);
        }
      } catch (error) {
        console.error('Hiba a szervezet nevének lekérésekor:', error);
      }
    };

    if (orgId) {
      fetchOrgName();
    }
  }, [orgId]);

  if (loading) return <LoadingSpinner />;

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      {/* Breadcrumb */}
      <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-2 text-[8pt]">
          <li className="flex items-center">
            <Link 
              to="/" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out flex items-center"
            >
              <svg 
                className="h-3 w-3 mr-1" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to="/organizations" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              Szervezetek
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to={`/organization/${orgId}`}
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              {organizationName || 'Betöltés...'}
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">
            Kimutatások
          </li>
        </ol>
      </nav>

      {/* Szűrők és táblázat konténer */}
      <div className="bg-gray-50 rounded-lg shadow-sm p-6">
        {/* Fejléc */}
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-2xl font-bold text-gray-900">Kimutatások</h2>
            <p className="mt-1 text-sm text-gray-500">
              Havi bontású kimutatások és statisztikák
            </p>
          </div>
          <button
            onClick={generatePDF}
            className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4" />
            </svg>
            PDF Letöltése
          </button>
        </div>

        {/* Szűrők */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6 bg-white p-4 rounded-lg shadow-sm">
          {/* Client Filter with Search */}
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Ügyfél
            </label>
            <Select
              value={selectedClientOption}
              onChange={(option) => setSelectedClient(option.value)}
              options={clientOptions}
              className="text-sm"
              classNamePrefix="react-select"
              placeholder="Keresés..."
              noOptionsMessage={() => "Nincs találat"}
              isClearable={false}
              isSearchable={true}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary: '#4F46E5', // indigo-600
                  primary75: '#6366F1', // indigo-500
                  primary50: '#EEF2FF', // indigo-50
                  primary25: '#F5F3FF', // indigo-50 lighter
                },
              })}
              styles={{
                control: (base) => ({
                  ...base,
                  borderColor: '#D1D5DB', // gray-300
                  '&:hover': {
                    borderColor: '#4F46E5' // indigo-600
                  },
                  boxShadow: 'none',
                  '&:focus-within': {
                    borderColor: '#4F46E5',
                    boxShadow: '0 0 0 1px #4F46E5'
                  }
                }),
                option: (base, state) => ({
                  ...base,
                  backgroundColor: state.isSelected 
                    ? '#4F46E5' 
                    : state.isFocused 
                      ? '#EEF2FF'
                      : 'white',
                  '&:active': {
                    backgroundColor: '#4F46E5'
                  }
                })
              }}
            />
          </div>

          {/* Month Filter */}
          <div>
            <label htmlFor="month" className="block text-sm font-medium text-gray-700">
              Hónap
            </label>
            <select
              id="month"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={selectedMonth}
              onChange={(e) => setSelectedMonth(parseInt(e.target.value))}
            >
              {months.map((month) => (
                <option key={month.value} value={month.value}>
                  {month.label}
                </option>
              ))}
            </select>
          </div>

          {/* Year Filter */}
          <div>
            <label htmlFor="year" className="block text-sm font-medium text-gray-700">
              Év
            </label>
            <select
              id="year"
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
              value={selectedYear}
              onChange={(e) => setSelectedYear(parseInt(e.target.value))}
            >
              {years.map((year) => (
                <option key={year} value={year}>
                  {year}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* Statisztikák */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-4 mb-6">
          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Összes bejelentés
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-gray-900">
                {taskStats.total}
              </dd>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Új bejelentések
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-blue-600">
                {taskStats.new}
              </dd>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Folyamatban lévő
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-yellow-600">
                {taskStats.inProgress}
              </dd>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Lezárt bejelentések
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-green-600">
                {taskStats.completed}
              </dd>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Összes munkaóra
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-indigo-600">
                {taskStats.totalHours.toFixed(1)}
              </dd>
            </div>
          </div>

          <div className="bg-white overflow-hidden shadow rounded-lg">
            <div className="p-5">
              <dt className="text-sm font-medium text-gray-500 truncate">
                Összes kiszállás
              </dt>
              <dd className="mt-1 text-3xl font-semibold text-purple-600">
                {taskStats.totalVisits}
              </dd>
            </div>
          </div>
        </div>

        {/* Táblázat */}
        <div className="bg-white shadow-sm rounded-lg overflow-hidden">
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Azonosító
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Tárgy
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Bejelentő
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Státusz
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Munkaóra
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Kiszállás
                  </th>
                  <th scope="col" className="px-3 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                    Létrehozva
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {tickets.map(ticket => (
                  <tr key={ticket.id} className="hover:bg-gray-50">
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                      {ticket.id.slice(0, 8)}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-900">
                      {ticket.title}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                      {ticket.contact?.name || 'Nincs megadva'}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm">
                      <span className={`inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium ${
                        ticket.status === 'new' ? 'bg-blue-100 text-blue-800' :
                        ticket.status === 'in_progress' ? 'bg-yellow-100 text-yellow-800' :
                        ticket.status === 'resolved' ? 'bg-green-100 text-green-800' :
                        ticket.status === 'waiting_for_client' ? 'bg-purple-100 text-purple-800' :
                        ticket.status === 'waiting_for_third_party' ? 'bg-indigo-100 text-indigo-800' :
                        ticket.status === 'collector' ? 'bg-emerald-100 text-emerald-800' :
                        'bg-gray-100 text-gray-800'
                      }`}>
                        {ticket.status === 'new' ? 'Új' :
                         ticket.status === 'in_progress' ? 'Folyamatban' :
                         ticket.status === 'resolved' ? 'Megoldva' :
                         ticket.status === 'closed' ? 'Lezárva' :
                         ticket.status === 'waiting_for_client' ? 'Ügyfél válaszára vár' :
                         ticket.status === 'waiting_for_third_party' ? 'Harmadik fél válaszára vár' :
                         ticket.status === 'collector' ? 'Gyűjtő ticket' : 'Státusz nincs beállítva'}
                      </span>
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                      {calculateHours(ticket.workTimes)} óra
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                      {(ticket.visits || []).length} db
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap text-sm text-gray-500">
                      {formatDate(ticket.createdAt)}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
} 
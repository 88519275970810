import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { collection, addDoc, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { useNavigate, useParams } from 'react-router-dom';
import { PROJECT_COLORS } from './ProjectList';
import { Link } from 'react-router-dom';

export default function NewProject() {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [code, setCode] = useState('');
  const [color, setColor] = useState('blue');
  const [deadline, setDeadline] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const { orgId } = useParams();
  const [organizationName, setOrganizationName] = useState('');

  useEffect(() => {
    async function fetchOrgName() {
      const orgDoc = await getDoc(doc(db, 'organizations', orgId));
      if (orgDoc.exists()) {
        setOrganizationName(orgDoc.data().name);
      }
    }
    fetchOrgName();
  }, [orgId]);

  // Automatikus kódgenerálás a név alapján
  const handleNameChange = (e) => {
    const newName = e.target.value;
    setName(newName);
    if (!code) {
      const autoCode = generateProjectCode(newName);
      setCode(autoCode);
    }
  };

  // Projekt kód generálása
  const generateProjectCode = (projectName) => {
    if (!projectName) return '';
    const prefix = projectName.slice(0, 3).toUpperCase();
    const number = Math.floor(Math.random() * 1000).toString().padStart(3, '0');
    return `${prefix}${number}`;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    try {
      // Ellenőrizzük, hogy a kód egyedi-e
      const codeQuery = query(
        collection(db, 'projects'),
        where('code', '==', code),
        where('organizationId', '==', orgId)
      );
      const codeSnapshot = await getDocs(codeQuery);

      if (!codeSnapshot.empty) {
        setError('Ez a projekt kód már használatban van');
        setLoading(false);
        return;
      }

      const projectData = {
        name,
        description,
        code,
        color,
        deadline: deadline ? new Date(deadline) : null,
        organizationId: orgId,
        status: 'active',
        createdAt: new Date(),
      };

      const docRef = await addDoc(collection(db, 'projects'), projectData);
      navigate(`/organization/${orgId}/projects/${docRef.id}`);
    } catch (error) {
      console.error('Hiba a projekt létrehozásakor:', error);
      setError('Hiba történt a projekt létrehozása során');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Breadcrumb */}
        <nav className="mb-8 bg-white shadow-sm rounded-lg px-4 py-2" aria-label="Breadcrumb">
          <ol className="flex items-center space-x-2 text-[8pt]">
            <li className="flex items-center">
              <Link to="/" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out flex items-center">
                <svg className="h-3 w-3 mr-1" fill="currentColor" viewBox="0 0 20 20">
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                Kezdőlap
              </Link>
            </li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li><Link to="/organizations" className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Szervezetek</Link></li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li><Link to={`/organization/${orgId}`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">{organizationName || 'Betöltés...'}</Link></li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li><Link to={`/organization/${orgId}/projects`} className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out">Projektek</Link></li>
            <li><svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor"><path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" /></svg></li>
            <li className="text-gray-500 font-medium">Új projekt</li>
          </ol>
        </nav>

        {/* Fejléc */}
        <div className="mb-8">
          <h1 className="text-3xl font-bold text-gray-900">Új projekt létrehozása</h1>
          <p className="mt-2 text-sm text-gray-600">
            Add meg az új projekt adatait a létrehozáshoz.
          </p>
        </div>

        {/* Űrlap kártya */}
        <div className="bg-white shadow-lg rounded-lg overflow-hidden">
          <div className="p-8">
            <form onSubmit={handleSubmit} className="space-y-6">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Projekt neve
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={handleNameChange}
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Projekt kód
                </label>
                <input
                  type="text"
                  value={code}
                  onChange={(e) => setCode(e.target.value.toUpperCase())}
                  required
                  pattern="[A-Z0-9]+"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Szín
                  </label>
                  <div className="mt-1 flex flex-wrap gap-2">
                    {Object.keys(PROJECT_COLORS).map((colorKey) => (
                      <button
                        key={colorKey}
                        type="button"
                        onClick={() => setColor(colorKey)}
                        className={`w-8 h-8 rounded-full border-2 transition-all duration-200 ${
                          PROJECT_COLORS[colorKey]
                        } ${
                          color === colorKey 
                            ? 'ring-2 ring-offset-2 ring-indigo-500'
                            : 'hover:ring-2 hover:ring-offset-2 hover:ring-gray-300'
                        }`}
                        title={colorKey.charAt(0).toUpperCase() + colorKey.slice(1)}
                      />
                    ))}
                  </div>
                </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Határidő
                  </label>
                  <input
                    type="date"
                    value={deadline}
                    onChange={(e) => setDeadline(e.target.value)}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Leírás
                </label>
                <textarea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                />
              </div>

              {error && (
                <div className="rounded-md bg-red-50 p-4">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <p className="text-sm text-red-700">{error}</p>
                    </div>
                  </div>
                </div>
              )}

              <div className="pt-5">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-indigo-400 transition-colors duration-200"
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Létrehozás...
                    </span>
                  ) : (
                    'Projekt létrehozása'
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
} 
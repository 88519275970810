import { useState, useEffect } from 'react';
import { useParams, Link } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';
import LoadingScreen from '../ui/LoadingScreen';
import { useAuth } from '../../contexts/AuthContext';
import ProjectList from '../project/ProjectList';

import SimpleTicketList from '../tickets/SimpleTicketList';
export default function OrganizationDetail() {
  const [organization, setOrganization] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { orgId } = useParams();
  const { currentUser } = useAuth();

  useEffect(() => {
    async function fetchOrganization() {
      try {
        const orgRef = doc(db, 'organizations', orgId);
        const orgSnap = await getDoc(orgRef);
        
        if (!orgSnap.exists()) {
          setError('A szervezet nem található');
          return;
        }

        const orgData = orgSnap.data();
        if (!orgData.members.includes(currentUser.uid)) {
          setError('Nincs jogosultsága a szervezet megtekintéséhez');
          return;
        }

        setOrganization({ id: orgSnap.id, ...orgData });
      } catch (err) {
        console.error('Hiba a szervezet betöltésekor:', err);
        setError('Hiba történt a szervezet betöltésekor');
      } finally {
        setLoading(false);
      }
    }

    fetchOrganization();
  }, [orgId, currentUser]);

  if (loading) return <LoadingScreen />;
  if (error) return <div className="text-red-600 text-center mt-4">{error}</div>;
  if (!organization) return null;

  return (
    <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8 py-4 sm:py-8">
      <nav className="mb-4 sm:mb-8 bg-white shadow-sm rounded-lg px-3 py-2" aria-label="Breadcrumb">
        <ol className="flex items-center space-x-1 sm:space-x-2 text-[7pt] sm:text-[8pt]">
          <li className="flex items-center">
            <Link 
              to="/" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out flex items-center"
            >
              <svg 
                className="h-3 w-3 mr-1" 
                fill="currentColor" 
                viewBox="0 0 20 20"
              >
                <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
              </svg>
              Kezdőlap
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="flex items-center">
            <Link 
              to="/organizations" 
              className="text-indigo-600 hover:text-indigo-900 transition-colors duration-150 ease-in-out"
            >
              Szervezetek
            </Link>
          </li>
          <li>
            <svg className="h-3 w-3 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clipRule="evenodd" />
            </svg>
          </li>
          <li className="text-gray-500 font-medium">
            {organization.name}
          </li>
        </ol>
      </nav>

      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-3 py-4 sm:px-6 sm:py-5">
          <div className="flex flex-col sm:flex-row sm:items-center sm:justify-between gap-4">
            <div>
              <h3 className="text-lg sm:text-xl leading-6 font-medium text-gray-900">
                {organization.name}
              </h3>
              <p className="mt-1 max-w-2xl text-xs sm:text-sm text-gray-500">
                Szervezet részletei
              </p>
            </div>
            <div className="flex flex-col sm:flex-row gap-2 sm:space-x-3">
              <Link
                to={`/organization/${orgId}/reports`}
                className="inline-flex items-center justify-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-xs sm:text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 mr-2" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg>
                Kimutatások
              </Link>
              <Link
                to={`/organization/${orgId}/settings`}
                className="inline-flex items-center justify-center px-3 py-2 border border-gray-300 rounded-md shadow-sm text-xs sm:text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
              >
                <svg 
                  xmlns="http://www.w3.org/2000/svg" 
                  className="h-4 w-4 mr-2" 
                  fill="none" 
                  viewBox="0 0 24 24" 
                  stroke="currentColor"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                </svg>
                Beállítások
              </Link>
            </div>
          </div>
        </div>

        <div className="border-t border-gray-200">
          <dl>
            <div className="bg-gray-50 px-3 py-3 sm:px-6 sm:py-5 grid grid-cols-3 gap-4">
              <dt className="text-xs sm:text-sm font-medium text-gray-500">
                Létrehozva
              </dt>
              <dd className="text-xs sm:text-sm text-gray-900 col-span-2">
                {organization.createdAt?.toDate?.() 
                  ? organization.createdAt.toDate().toLocaleDateString()
                  : 'Nem elérhető'}
              </dd>
            </div>
            <div className="bg-white px-3 py-3 sm:px-6 sm:py-5 grid grid-cols-3 gap-4">
              <dt className="text-xs sm:text-sm font-medium text-gray-500">
                Tagok száma
              </dt>
              <dd className="text-xs sm:text-sm text-gray-900 col-span-2">
                {organization.members?.length || 0}
              </dd>
            </div>
          </dl>
        </div>
   
      </div>
      
      <div className="mt-4 sm:mt-8">
        <ProjectList organizationId={orgId} />
      </div>
      <div className="mt-4 sm:mt-8">
        <SimpleTicketList organizationId={orgId} />
      </div>
    </div>
  );
}

import { useState, useEffect } from 'react';
import { db } from '../../firebase/config';
import { doc, getDoc } from 'firebase/firestore';

export default function UserSelect({ organizationId, selectedUsers, onChange }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchOrganizationUsers() {
      try {
        const orgRef = doc(db, 'organizations', organizationId);
        const orgSnap = await getDoc(orgRef);
        
        if (!orgSnap.exists()) return;
        
        const orgData = orgSnap.data();
        const memberIds = orgData.members || [];
        
        // Fetch user details for each member
        const userPromises = memberIds.map(async (userId) => {
          const userRef = doc(db, 'users', userId);
          const userSnap = await getDoc(userRef);
          if (userSnap.exists()) {
            return { id: userSnap.id, ...userSnap.data() };
          }
          return null;
        });
        
        const userDetails = (await Promise.all(userPromises)).filter(user => user !== null);
        setUsers(userDetails);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);
      }
    }

    fetchOrganizationUsers();
  }, [organizationId]);

  const handleUserToggle = (userId) => {
    const newSelected = selectedUsers.includes(userId)
      ? selectedUsers.filter(id => id !== userId)
      : [...selectedUsers, userId];
    onChange(newSelected);
  };

  if (loading) return <div>Betöltés...</div>;

  return (
    <div className="space-y-2">
      {users.map(user => (
        <label key={user.id} className="flex items-center space-x-3">
          <input
            type="checkbox"
            checked={selectedUsers.includes(user.id)}
            onChange={() => handleUserToggle(user.id)}
            className="h-4 w-4 text-indigo-600 rounded border-gray-300 focus:ring-indigo-500"
          />
          <span className="text-sm">
            <span className="font-medium">{user.displayName || 'Névtelen'}</span>
            <span className="text-gray-500 ml-2">({user.email})</span>
          </span>
        </label>
      ))}
    </div>
  );
} 
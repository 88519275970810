import { collection, addDoc } from 'firebase/firestore';
import { db } from '../firebase/config';

export const createNotification = async ({
  userId,
  title,
  message,
  type,
  link,
  metadata = {}
}) => {
  try {
    await addDoc(collection(db, 'notifications'), {
      userId,
      title,
      message,
      type,
      link,
      metadata,
      read: false,
      createdAt: new Date()
    });
  } catch (error) {
    console.error('Error creating notification:', error);
  }
}; 
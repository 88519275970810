import { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { db } from '../../firebase/config';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { useAuth } from '../../contexts/AuthContext';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { storage } from '../../firebase/config';

export default function ClientDetail() {
  const { orgId, clientId } = useParams();
  const navigate = useNavigate();
  const { currentUser } = useAuth();
  const [client, setClient] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editMode, setEditMode] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    taxNumber: '',
    notes: '',
    hourlyRate: 0,
    travelRate: 0,
    currency: 'HUF'
  });
  const [uploading, setUploading] = useState(false);
  const [documents, setDocuments] = useState([]);
  const fileInputRef = useRef(null);
  const [documentMeta, setDocumentMeta] = useState({
    title: '',
    type: 'other'
  });
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [editingDocument, setEditingDocument] = useState(null);
  const [organization, setOrganization] = useState(null);
  const [showContactModal, setShowContactModal] = useState(false);
  const [editingContact, setEditingContact] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const orgDoc = await getDoc(doc(db, 'organizations', orgId));
        if (orgDoc.exists()) {
          const orgData = orgDoc.data();
          setOrganization(orgData);
          
          const clientData = orgData.clients.find(c => c.id === clientId);
          if (clientData) {
            setClient(clientData);
            setFormData(clientData);
          } else {
            setError('Az ügyfél nem található');
          }
        } else {
          setError('A szervezet nem található');
        }
      } catch (error) {
        setError('Hiba történt az adatok betöltésekor: ' + error.message);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, [orgId, clientId]);

  const isAdmin = organization?.admins?.includes(currentUser?.uid) || organization?.adminId === currentUser?.uid;

  async function handleUpdate(e, directData = null) {
    if (e) {
      e.preventDefault();
    }
    
    setLoading(true);
    try {
      const orgRef = doc(db, 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);
      
      if (!orgDoc.exists()) {
        throw new Error('A szervezet nem található');
      }

      const dataToUpdate = directData || formData;

      const updatedClients = orgDoc.data().clients.map(c => 
        c.id === clientId 
          ? { 
              ...dataToUpdate, 
              updatedAt: new Date().toISOString(),
              updatedBy: {
                id: currentUser.uid,
                email: currentUser.email
              }
            }
          : c
      );

      await updateDoc(orgRef, { clients: updatedClients });
      setClient({ ...dataToUpdate, updatedAt: new Date().toISOString() });
      setEditMode(false);
    } catch (error) {
      setError('Hiba történt a módosítás során: ' + error.message);
    } finally {
      setLoading(false);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  }

  const handleFileUpload = async (e) => {
    e.preventDefault();
    if (!fileInputRef.current?.files?.length) return;
    
    const files = Array.from(fileInputRef.current.files);
    setUploading(true);
    
    try {
      for (const file of files) {
        const fileName = `${Date.now()}_${file.name}`;
        const storageRef = ref(storage, `organizations/${orgId}/clients/${clientId}/documents/${fileName}`);
        
        await uploadBytes(storageRef, file);
        const downloadURL = await getDownloadURL(storageRef);
        
        const documentData = {
          id: Date.now().toString(),
          name: file.name,
          title: documentMeta.title || file.name,
          type: documentMeta.type,
          path: `organizations/${orgId}/clients/${clientId}/documents/${fileName}`,
          url: downloadURL,
          fileType: file.type,
          size: file.size,
          uploadedAt: new Date().toISOString(),
          uploadedBy: {
            id: currentUser.uid,
            email: currentUser.email,
            name: currentUser.displayName || currentUser.email
          }
        };

        const orgRef = doc(db, 'organizations', orgId);
        const orgDoc = await getDoc(orgRef);
        
        const updatedClients = orgDoc.data().clients.map(c => 
          c.id === clientId 
            ? { 
                ...c,
                documents: [...(c.documents || []), documentData]
              }
            : c
        );

        await updateDoc(orgRef, { clients: updatedClients });
        
        setClient(prev => ({
          ...prev,
          documents: [...(prev.documents || []), documentData]
        }));
      }
      
      setDocumentMeta({ title: '', type: 'other' });
      setShowUploadForm(false);
      
    } catch (error) {
      setError('Hiba történt a dokumentum feltöltése során: ' + error.message);
    } finally {
      setUploading(false);
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    }
  };

  const handleDeleteDocument = async (document) => {
    try {
      const storageRef = ref(storage, document.path);
      await deleteObject(storageRef);

      const orgRef = doc(db, 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);
      
      const updatedClients = orgDoc.data().clients.map(c => 
        c.id === clientId 
          ? { 
              ...c,
              documents: (c.documents || []).filter(d => d.id !== document.id)
            }
          : c
      );

      await updateDoc(orgRef, { clients: updatedClients });
      
      setClient(prev => ({
        ...prev,
        documents: (prev.documents || []).filter(d => d.id !== document.id)
      }));
    } catch (error) {
      setError('Hiba történt a dokumentum törlése során: ' + error.message);
    }
  };

  const handleEditDocument = async (e) => {
    e.preventDefault();
    if (!editingDocument) return;

    try {
      const orgRef = doc(db, 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);
      
      const updatedClients = orgDoc.data().clients.map(c => 
        c.id === clientId 
          ? { 
              ...c,
              documents: c.documents.map(d => 
                d.id === editingDocument.id 
                  ? {
                      ...d,
                      title: editingDocument.title,
                      type: editingDocument.type,
                      updatedAt: new Date().toISOString(),
                      updatedBy: {
                        id: currentUser.uid,
                        email: currentUser.email,
                        name: currentUser.displayName || currentUser.email
                      }
                    }
                  : d
              )
            }
          : c
      );

      await updateDoc(orgRef, { clients: updatedClients });
      
      setClient(prev => ({
        ...prev,
        documents: prev.documents.map(d => 
          d.id === editingDocument.id 
            ? {
                ...d,
                title: editingDocument.title,
                type: editingDocument.type,
                updatedAt: new Date().toISOString()
              }
            : d
        )
      }));

      setEditingDocument(null);
    } catch (error) {
      setError('Hiba történt a dokumentum módosítása során: ' + error.message);
    }
  };

  const handleDeleteClient = async () => {
    if (!isAdmin) {
      setError('Nincs jogosultsága az ügyfél törléséhez!');
      return;
    }

    if (!window.confirm('Biztosan törölni szeretné ezt az ügyfelet? Ez a művelet nem vonható vissza.')) {
      return;
    }

    setLoading(true);
    try {
      // Először töröljük az összes dokumentumot a Storage-ból
      if (client.documents?.length > 0) {
        for (const doc of client.documents) {
          const storageRef = ref(storage, doc.path);
          await deleteObject(storageRef);
        }
      }

      // Majd töröljük az ügyfelet a Firestore-ból
      const orgRef = doc(db, 'organizations', orgId);
      const orgDoc = await getDoc(orgRef);
      
      const updatedClients = orgDoc.data().clients.filter(c => c.id !== clientId);
      await updateDoc(orgRef, { clients: updatedClients });

      // Átirányítás az ügyfelek listájához
      navigate(`/organization/${orgId}/clients`);
    } catch (error) {
      setError('Hiba történt az ügyfél törlésekor: ' + error.message);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-indigo-600"></div>
      </div>
    );
  }

  if (!client) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative">
          {error || 'Az ügyfél nem található'}
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <div className="bg-white shadow overflow-hidden sm:rounded-lg">
        <div className="px-4 py-5 sm:px-6 flex justify-between items-center">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Ügyfél adatai
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Azonosító: {client.id}
            </p>
          </div>
          <div className="flex space-x-3">
            <button
              type="button"
              onClick={() => navigate(-1)}
              className="inline-flex items-center px-3 py-2 border border-gray-300 shadow-sm text-sm leading-4 font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Vissza
            </button>
            <button
              onClick={() => setEditMode(true)}
              className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Szerkesztés
            </button>
            {!editMode && isAdmin && (
              <button
                onClick={handleDeleteClient}
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                {loading ? 'Törlés...' : 'Ügyfél törlése'}
              </button>
            )}
          </div>
        </div>

        {error && (
          <div className="px-4 py-3 bg-red-100 border border-red-400 text-red-700">
            {error}
          </div>
        )}

        {editMode ? (
          <form onSubmit={handleUpdate} className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-700">
                  Név *
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.name}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                  Email *
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  required
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.email}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                  Telefonszám
                </label>
                <input
                  type="tel"
                  name="phone"
                  id="phone"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.phone}
                  onChange={handleChange}
                />
              </div>

              <div>
                <label htmlFor="taxNumber" className="block text-sm font-medium text-gray-700">
                  Adószám
                </label>
                <input
                  type="text"
                  name="taxNumber"
                  id="taxNumber"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.taxNumber}
                  onChange={handleChange}
                />
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="address" className="block text-sm font-medium text-gray-700">
                  Cím
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.address}
                  onChange={handleChange}
                />
              </div>

              <div className="sm:col-span-2">
                <label htmlFor="notes" className="block text-sm font-medium text-gray-700">
                  Megjegyzések
                </label>
                <textarea
                  name="notes"
                  id="notes"
                  rows={3}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  value={formData.notes}
                  onChange={handleChange}
                />
              </div>

              <div className="grid grid-cols-1 gap-6 sm:grid-cols-3">
                <div>
                  <label htmlFor="hourlyRate" className="block text-sm font-medium text-gray-700">
                    Óradíj
                  </label>
                  <input
                    type="number"
                    name="hourlyRate"
                    id="hourlyRate"
                    min="0"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={formData.hourlyRate}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="travelRate" className="block text-sm font-medium text-gray-700">
                    Kiszállási díj
                  </label>
                  <input
                    type="number"
                    name="travelRate"
                    id="travelRate"
                    min="0"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={formData.travelRate}
                    onChange={handleChange}
                  />
                </div>

                <div>
                  <label htmlFor="currency" className="block text-sm font-medium text-gray-700">
                    Pénznem
                  </label>
                  <select
                    name="currency"
                    id="currency"
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    value={formData.currency}
                    onChange={handleChange}
                  >
                    <option value="HUF">HUF</option>
                    <option value="EUR">EUR</option>
                    <option value="USD">USD</option>
                  </select>
                </div>
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={() => setEditMode(false)}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Mégse
              </button>
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                {loading ? 'Mentés...' : 'Módosítások mentése'}
              </button>
            </div>
          </form>
        ) : (
          <div className="border-t border-gray-200">
            <dl>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Név</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{client.name}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Email</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{client.email}</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Telefonszám</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{client.phone || '-'}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Adószám</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{client.taxNumber || '-'}</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Cím</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{client.address || '-'}</dd>
              </div>
              <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Megjegyzések</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">{client.notes || '-'}</dd>
              </div>
              <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Óradíj</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {client.hourlyRate} {client.currency}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Kiszállási díj</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {client.travelRate || 0} {client.currency}
                </dd>
              </div>
              <div className="bg-gray-50 px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                <dt className="text-sm font-medium text-gray-500">Létrehozva</dt>
                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                  {new Date(client.createdAt).toLocaleDateString()}
                </dd>
              </div>
              {client.updatedAt && (
                <div className="px-4 py-2 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt className="text-sm font-medium text-gray-500">Utoljára módosítva</dt>
                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                    {new Date(client.updatedAt).toLocaleDateString()}
                  </dd>
                </div>
              )}
            </dl>
          </div>
        )}
      </div>
      <div className="mt-8 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Kapcsolattartók
            </h3>
            {!editMode && (
              <button
                onClick={() => {
                  setEditingContact(null);
                  setShowContactModal(true);
                }}
                className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Új kapcsolattartó
              </button>
            )}
          </div>

          <div className="mt-4 space-y-4">
            {client.contacts?.map((contact, index) => (
              <div key={index} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                <div className="flex items-center space-x-4">
                  <div>
                    <h4 className="text-sm font-medium text-gray-900">{contact.name}</h4>
                    <div className="mt-1 text-sm text-gray-500">
                      {contact.position && <p>Pozíció: {contact.position}</p>}
                      {contact.email && (
                        <p>
                          Email:{' '}
                          <a href={`mailto:${contact.email}`} className="text-indigo-600 hover:text-indigo-900">
                            {contact.email}
                          </a>
                        </p>
                      )}
                      {contact.phone && (
                        <p>
                          Telefon:{' '}
                          <a href={`tel:${contact.phone}`} className="text-indigo-600 hover:text-indigo-900">
                            {contact.phone}
                          </a>
                        </p>
                      )}
                    </div>
                  </div>
                </div>
                {!editMode && (
                  <div className="flex space-x-2">
                    <button
                      onClick={() => {
                        setEditingContact({ ...contact, index });
                        setShowContactModal(true);
                      }}
                      className="text-indigo-600 hover:text-indigo-900"
                    >
                      Szerkesztés
                    </button>
                    <button
                      onClick={async () => {
                        if (window.confirm('Biztosan törli ezt a kapcsolattartót?')) {
                          const updatedContacts = client.contacts.filter((_, i) => i !== index);
                          const updatedClient = { ...client, contacts: updatedContacts };
                          await handleUpdate(null, updatedClient);
                        }
                      }}
                      className="text-red-600 hover:text-red-900"
                    >
                      Törlés
                    </button>
                  </div>
                )}
              </div>
            ))}
            {(!client.contacts || client.contacts.length === 0) && (
              <p className="text-center text-gray-500">Nincsenek kapcsolattartók</p>
            )}
          </div>
        </div>
      </div>
      <div className="mt-8 bg-white shadow sm:rounded-lg">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Dokumentumok
            </h3>
            <button
              onClick={() => setShowUploadForm(true)}
              className="inline-flex items-center px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Új dokumentum feltöltése
            </button>
          </div>

          {showUploadForm && (
            <form onSubmit={handleFileUpload} className="mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Dokumentum címe
                  </label>
                  <input
                    type="text"
                    value={documentMeta.title}
                    onChange={(e) => setDocumentMeta(prev => ({ ...prev, title: e.target.value }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="pl. Szolgáltatási szerződés 2024"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Dokumentum típusa
                  </label>
                  <select
                    value={documentMeta.type}
                    onChange={(e) => setDocumentMeta(prev => ({ ...prev, type: e.target.value }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="contract">Szerződés</option>
                    <option value="invoice">Számla</option>
                    <option value="offer">Árajánlat</option>
                    <option value="other">Egyéb</option>
                  </select>
                </div>
              </div>
              
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Fájl kiválasztása
                </label>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                  required
                  multiple
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                />
              </div>

              <div className="mt-4 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowUploadForm(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Mégse
                </button>
                <button
                  type="submit"
                  disabled={uploading}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {uploading ? 'Feltöltés...' : 'Feltöltés'}
                </button>
              </div>
            </form>
          )}

          <div className="mt-4 space-y-4">
            {client?.documents?.length > 0 ? (
              client.documents.map((doc) => (
                <div key={doc.id} className="flex items-center justify-between p-4 bg-gray-50 rounded-lg">
                  {editingDocument?.id === doc.id ? (
                    <form onSubmit={handleEditDocument} className="w-full">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Dokumentum címe
                          </label>
                          <input
                            type="text"
                            value={editingDocument.title}
                            onChange={(e) => setEditingDocument(prev => ({ ...prev, title: e.target.value }))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            required
                          />
                        </div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Dokumentum típusa
                          </label>
                          <select
                            value={editingDocument.type}
                            onChange={(e) => setEditingDocument(prev => ({ ...prev, type: e.target.value }))}
                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                          >
                            <option value="contract">Szerződés</option>
                            <option value="invoice">Számla</option>
                            <option value="offer">Árajánlat</option>
                            <option value="other">Egyéb</option>
                          </select>
                        </div>
                      </div>
                      <div className="mt-4 flex justify-end space-x-2">
                        <button
                          type="button"
                          onClick={() => setEditingDocument(null)}
                          className="px-3 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
                        >
                          Mégse
                        </button>
                        <button
                          type="submit"
                          className="px-3 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                        >
                          Mentés
                        </button>
                      </div>
                    </form>
                  ) : (
                    <>
                      <div className="flex items-center space-x-4">
                        <div>
                          <h4 className="text-sm font-medium text-gray-900">{doc.title}</h4>
                          <p className="text-sm text-gray-500">
                            {doc.type === 'contract' && 'Szerződés'}
                            {doc.type === 'invoice' && 'Számla'}
                            {doc.type === 'offer' && 'Árajánlat'}
                            {doc.type === 'other' && 'Egyéb'}
                          </p>
                          <div className="flex items-center space-x-2 mt-1">
                            <a 
                              href={doc.url} 
                              target="_blank" 
                              rel="noopener noreferrer"
                              className="text-xs text-indigo-600 hover:text-indigo-900"
                            >
                              {doc.name}
                            </a>
                            <span className="text-xs text-gray-500">
                              {new Date(doc.uploadedAt).toLocaleDateString()}
                            </span>
                          </div>
                        </div>
                        <div className="flex space-x-2">
                          <button
                            onClick={() => setEditingDocument(doc)}
                            className="text-indigo-600 hover:text-indigo-900"
                          >
                            <span className="sr-only">Szerkesztés</span>
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clipRule="evenodd" />
                            </svg>
                          </button>
                          <button
                            onClick={() => handleDeleteDocument(doc)}
                            className="text-red-600 hover:text-red-900"
                          >
                            <span className="sr-only">Törlés</span>
                            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
                              <path fillRule="evenodd" d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z" clipRule="evenodd" />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ))
            ) : (
              <p className="text-center text-gray-500">Nincsenek feltöltött dokumentumok</p>
            )}
          </div>
        </div>
      </div>
      {showContactModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              {editingContact ? 'Kapcsolattartó szerkesztése' : 'Új kapcsolattartó'}
            </h3>
            <form onSubmit={async (e) => {
              e.preventDefault();
              const formData = new FormData(e.target);
              const contactData = {
                name: formData.get('name'),
                position: formData.get('position'),
                email: formData.get('email'),
                phone: formData.get('phone')
              };

              const updatedContacts = [...(client.contacts || [])];
              if (editingContact) {
                updatedContacts[editingContact.index] = contactData;
              } else {
                updatedContacts.push(contactData);
              }

              const updatedClient = { ...client, contacts: updatedContacts };
              await handleUpdate(null, updatedClient);
              
              setShowContactModal(false);
              setEditingContact(null);
            }}>
              <div className="space-y-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700">Név *</label>
                  <input
                    type="text"
                    name="name"
                    required
                    defaultValue={editingContact?.name}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Pozíció</label>
                  <input
                    type="text"
                    name="position"
                    defaultValue={editingContact?.position}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Email *</label>
                  <input
                    type="email"
                    name="email"
                    required
                    defaultValue={editingContact?.email}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">Telefon</label>
                  <input
                    type="tel"
                    name="phone"
                    defaultValue={editingContact?.phone}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  />
                </div>
              </div>
              <div className="mt-6 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowContactModal(false);
                    setEditingContact(null);
                  }}
                  className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50"
                >
                  Mégsem
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                >
                  {editingContact ? 'Mentés' : 'Hozzáadás'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
      {showUploadForm && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Dokumentum feltöltése
            </h3>
            <form onSubmit={handleFileUpload} className="mb-6 p-4 bg-gray-50 rounded-lg">
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Dokumentum címe
                  </label>
                  <input
                    type="text"
                    value={documentMeta.title}
                    onChange={(e) => setDocumentMeta(prev => ({ ...prev, title: e.target.value }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    placeholder="pl. Szolgáltatási szerződés 2024"
                    required
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Dokumentum típusa
                  </label>
                  <select
                    value={documentMeta.type}
                    onChange={(e) => setDocumentMeta(prev => ({ ...prev, type: e.target.value }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  >
                    <option value="contract">Szerződés</option>
                    <option value="invoice">Számla</option>
                    <option value="offer">Árajánlat</option>
                    <option value="other">Egyéb</option>
                  </select>
                </div>
              </div>
              
              <div className="mt-4">
                <label className="block text-sm font-medium text-gray-700">
                  Fájl kiválasztása
                </label>
                <input
                  type="file"
                  ref={fileInputRef}
                  className="mt-1 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
                  required
                  multiple
                  accept=".pdf,.doc,.docx,.xls,.xlsx,.jpg,.jpeg,.png"
                />
              </div>

              <div className="mt-4 flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setShowUploadForm(false)}
                  className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Mégse
                </button>
                <button
                  type="submit"
                  disabled={uploading}
                  className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  {uploading ? 'Feltöltés...' : 'Feltöltés'}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
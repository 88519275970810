import { useState, useEffect, useCallback } from 'react';
import { db, storage } from '../../firebase/config';
import { collection, addDoc, query, where, orderBy, getDocs, deleteDoc, doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { useAuth } from '../../contexts/AuthContext';
import { createNotification } from '../../utils/notifications';
import { useParams } from 'react-router-dom';

// Új komponens a kép előnézethez
const ImagePreview = ({ attachment }) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <div className="relative group">
      <a
        href={attachment.url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-2 text-sm text-indigo-600 hover:text-indigo-500"
        onMouseEnter={() => setShowPreview(true)}
        onMouseLeave={() => setShowPreview(false)}
      >
        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M4 3a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V5a2 2 0 00-2-2H4zm12 12H4l4-8 3 6 2-4 3 6z" clipRule="evenodd" />
        </svg>
        <span>{attachment.name}</span>
      </a>
      
      {showPreview && (
        <div className="absolute z-10 bottom-full left-0 mb-2 p-2 bg-white rounded-lg shadow-xl border border-gray-200">
          <img 
            src={attachment.url} 
            alt={attachment.name}
            className="max-w-2xl max-h-96 object-contain"
          />
        </div>
      )}
    </div>
  );
};

// PDF előnézet komponens
const PDFPreview = ({ attachment }) => {
  const [showPreview, setShowPreview] = useState(false);

  return (
    <div className="relative group">
      <a
        href={attachment.url}
        target="_blank"
        rel="noopener noreferrer"
        className="flex items-center space-x-2 text-sm text-indigo-600 hover:text-indigo-500"
        onMouseEnter={() => setShowPreview(true)}
        onMouseLeave={() => setShowPreview(false)}
      >
        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
        </svg>
        <span>{attachment.name}</span>
      </a>
      
      {showPreview && (
        <div className="absolute z-10 bottom-full left-0 mb-2 p-2 bg-white rounded-lg shadow-xl border border-gray-200">
          <iframe
            src={`${attachment.url}#view=FitH`}
            title={attachment.name}
            className="w-[800px] h-[600px] border-0"
          />
        </div>
      )}
    </div>
  );
};

export default function TaskComments({ taskId, assignedUsers }) {
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [files, setFiles] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [error, setError] = useState(null);
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedContent, setEditedContent] = useState('');
  const [pastedImages, setPastedImages] = useState([]);
  const { currentUser } = useAuth();
  const { orgId, projectId } = useParams();

  useEffect(() => {
    if (taskId) {
      fetchComments();
    }
  }, [taskId]);

  const fetchComments = async () => {
    try {
      const q = query(
        collection(db, 'taskComments'),
        where('taskId', '==', taskId),
        orderBy('createdAt', 'desc')
      );
      const querySnapshot = await getDocs(q);
      const commentsData = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setComments(commentsData);
    } catch (error) {
      console.error('Hiba a kommentek betöltésekor:', error);
      setError('Hiba történt a kommentek betöltése során');
    }
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);
    setFiles(selectedFiles);
  };

  const handlePaste = useCallback(async (e) => {
    const items = e.clipboardData?.items;
    if (!items) return;

    const imageItems = Array.from(items).filter(
      item => item.type.indexOf('image') !== -1
    );

    for (const item of imageItems) {
      const file = item.getAsFile();
      if (file) {
        // Előnézeti URL generálása
        const previewUrl = URL.createObjectURL(file);
        setPastedImages(prev => [...prev, { file, previewUrl }]);
      }
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!newComment.trim() && files.length === 0 && pastedImages.length === 0) return;

    setUploading(true);
    setError(null);

    try {
      const attachments = [];

      // Feltöltjük a kiválasztott fájlokat
      for (const file of [...files, ...pastedImages.map(img => img.file)]) {
        try {
          const timestamp = new Date().getTime();
          const safeFileName = encodeURIComponent(file.name || `pasted-image-${timestamp}.png`);
          const fileName = `${timestamp}-${safeFileName}`;
          const storageRef = ref(storage, `tasks/${taskId}/attachments/${fileName}`);
          
          await uploadBytes(storageRef, file);
          const downloadURL = await getDownloadURL(storageRef);
          
          attachments.push({
            name: file.name || `Beillesztett kép ${new Date().toLocaleString()}`,
            url: downloadURL,
            type: file.type,
            size: file.size,
            uploadedAt: new Date()
          });
        } catch (fileError) {
          console.error('Hiba a fájl feltöltésekor:', fileError);
          setError(`Hiba a fájl feltöltésekor`);
          continue;
        }
      }

      // Komment létrehozása (még ha nincs is feltöltött fájl)
      if (newComment.trim() || attachments.length > 0) {
        const commentRef = await addDoc(collection(db, 'taskComments'), {
          taskId,
          content: newComment.trim(),
          attachments,
          createdBy: {
            id: currentUser.uid,
            name: currentUser.displayName || currentUser.email
          },
          createdAt: serverTimestamp()
        });

        // Form reset
        setNewComment('');
        setFiles([]);
        setPastedImages([]);
        
        // Kommentek újratöltése
        await fetchComments();

        // Értesítés küldése minden hozzárendelt felhasználónak
        const notificationPromises = assignedUsers
          .map(userId => 
            createNotification({
              userId,
              title: '📝 Új hozzászólás',
              message: `${currentUser.displayName || currentUser.email} új hozzászólást írt: "${newComment.substring(0, 50)}${newComment.length > 50 ? '...' : ''}"`,
              type: 'comment_added',
              link: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}#comment-${commentRef.id}`,
              metadata: {
                taskId,
                projectId,
                orgId,
                commentId: commentRef.id,
                taskUrl: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}`
              }
            })
          );

        await Promise.all(notificationPromises);
      }
    } catch (error) {
      console.error('Hiba a komment küldésekor:', error);
      setError('Hiba történt a komment küldése során');
    } finally {
      setUploading(false);
    }
  };

  const handleEdit = (comment) => {
    setEditingCommentId(comment.id);
    setEditedContent(comment.content);
  };

  const handleUpdate = async (commentId) => {
    try {
      const commentRef = doc(db, 'taskComments', commentId);
      await updateDoc(commentRef, {
        content: editedContent,
        updatedAt: new Date()
      });

      setComments(comments.map(comment => 
        comment.id === commentId 
          ? { ...comment, content: editedContent, updatedAt: new Date() }
          : comment
      ));
      setEditingCommentId(null);
      setEditedContent('');

      // Értesítés küldése minden hozzárendelt felhasználónak
      const notificationPromises = assignedUsers
        .map(userId => 
          createNotification({
            userId,
            title: '✏️ Hozzászólás módosítva',
            message: `${currentUser.displayName || currentUser.email} módosított egy hozzászólást`,
            type: 'comment_edited',
            link: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}#comment-${commentId}`,
            metadata: {
              taskId,
              projectId,
              orgId,
              commentId,
              taskUrl: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}`
            }
          })
        );

      await Promise.all(notificationPromises);
    } catch (error) {
      console.error('Hiba a komment módosításakor:', error);
      setError('Hiba történt a komment módosítása során');
    }
  };

  const handleDelete = async (comment) => {
    if (!window.confirm('Biztosan törölni szeretnéd ezt a kommentet?')) return;

    try {
      // Fájlok törlése a storage-ból
      if (comment.attachments && comment.attachments.length > 0) {
        for (const attachment of comment.attachments) {
          const fileRef = ref(storage, attachment.url);
          try {
            await deleteObject(fileRef);
          } catch (error) {
            console.error('Hiba a fájl törlésekor:', error);
          }
        }
      }

      // Komment törlése a Firestore-ból
      await deleteDoc(doc(db, 'taskComments', comment.id));
      setComments(comments.filter(c => c.id !== comment.id));

      // Értesítés küldése minden hozzárendelt felhasználónak
      const notificationPromises = assignedUsers
        .map(userId => 
          createNotification({
            userId,
            title: '🗑️ Hozzászólás törölve',
            message: `${currentUser.displayName || currentUser.email} törölt egy hozzászólást`,
            type: 'comment_deleted',
            link: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}`,
            metadata: {
              taskId,
              projectId,
              orgId,
              commentId: comment.id,
              taskUrl: `/organization/${orgId}/projects/${projectId}/tasks/${taskId}`
            }
          })
        );

      await Promise.all(notificationPromises);
    } catch (error) {
      console.error('Hiba a komment törlésekor:', error);
      setError('Hiba történt a komment törlése során');
    }
  };

  return (
    <div className="mt-8">
      <h3 className="text-lg font-medium text-gray-900 mb-4">Kommentek</h3>

      {/* Komment form */}
      <form onSubmit={handleSubmit} className="mb-6">
        <div className="mb-4">
          <textarea
            value={newComment}
            onChange={(e) => setNewComment(e.target.value)}
            onPaste={handlePaste}
            placeholder="Írj kommentet... (Képet a vágólapról beilleszthetsz)"
            rows={3}
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
          />
        </div>

        {/* Beillesztett képek előnézete */}
        {pastedImages.length > 0 && (
          <div className="mb-4 flex flex-wrap gap-2">
            {pastedImages.map((img, index) => (
              <div key={index} className="relative">
                <img 
                  src={img.previewUrl} 
                  alt="Előnézet" 
                  className="h-20 w-20 object-cover rounded"
                />
                <button
                  type="button"
                  onClick={() => {
                    URL.revokeObjectURL(img.previewUrl);
                    setPastedImages(prev => prev.filter((_, i) => i !== index));
                  }}
                  className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full p-1 hover:bg-red-600"
                >
                  <svg className="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                </button>
              </div>
            ))}
          </div>
        )}

        <div className="flex items-center justify-between">
          <div className="flex items-center space-x-4">
            <input
              type="file"
              multiple
              onChange={handleFileChange}
              className="block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-md file:border-0 file:text-sm file:font-medium file:bg-indigo-50 file:text-indigo-700 hover:file:bg-indigo-100"
            />
            {files.length > 0 && (
              <span className="text-sm text-gray-500">
                {files.length} fájl kiválasztva
              </span>
            )}
          </div>
          <button
            type="submit"
            disabled={uploading || (!newComment.trim() && files.length === 0 && pastedImages.length === 0)}
            className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 disabled:opacity-50"
          >
            {uploading ? 'Küldés...' : 'Küldés'}
          </button>
        </div>
      </form>

      {/* Kommentek listája */}
      <div className="space-y-4">
        {comments.map(comment => (
          <div key={comment.id} className="bg-white shadow sm:rounded-lg p-4">
            <div className="flex justify-between items-start">
              <div className="flex items-start space-x-3">
                <div className="flex-shrink-0">
                  <div className="h-8 w-8 rounded-full bg-gray-200 flex items-center justify-center">
                    <span className="text-sm font-medium text-gray-500">
                      {comment.createdBy.name?.substring(0, 2).toUpperCase()}
                    </span>
                  </div>
                </div>
                <div>
                  <p className="text-sm font-medium text-gray-900">
                    {comment.createdBy.name}
                  </p>
                  <p className="text-xs text-gray-500">
                    {new Date(comment.createdAt?.toDate()).toLocaleString()}
                  </p>
                </div>
              </div>
              
              {/* Szerkesztés/törlés gombok */}
              {currentUser.uid === comment.createdBy.id && (
                <div className="flex space-x-2">
                  <button
                    onClick={() => handleEdit(comment)}
                    className="text-sm text-gray-600 hover:text-gray-900"
                  >
                    Szerkesztés
                  </button>
                  <button
                    onClick={() => handleDelete(comment)}
                    className="text-sm text-red-600 hover:text-red-900"
                  >
                    Törlés
                  </button>
                </div>
              )}
            </div>

            {/* Komment tartalma */}
            {editingCommentId === comment.id ? (
              <div className="mt-3">
                <textarea
                  value={editedContent}
                  onChange={(e) => setEditedContent(e.target.value)}
                  className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                  rows={3}
                />
                <div className="mt-2 flex justify-end space-x-2">
                  <button
                    onClick={() => setEditingCommentId(null)}
                    className="px-3 py-1 text-sm text-gray-600 hover:text-gray-900"
                  >
                    Mégse
                  </button>
                  <button
                    onClick={() => handleUpdate(comment.id)}
                    className="px-3 py-1 bg-indigo-600 text-white rounded-md hover:bg-indigo-700 text-sm"
                  >
                    Mentés
                  </button>
                </div>
              </div>
            ) : (
              <p className="mt-2 text-sm text-gray-700">{comment.content}</p>
            )}

            {/* Csatolt fájlok */}
            {comment.attachments && comment.attachments.length > 0 && (
              <div className="mt-3 space-y-2">
                {comment.attachments.map((attachment, index) => {
                  if (attachment.type?.startsWith('image/')) {
                    return <ImagePreview key={index} attachment={attachment} />;
                  } else if (attachment.type === 'application/pdf') {
                    return <PDFPreview key={index} attachment={attachment} />;
                  } else {
                    return (
                      <a
                        key={index}
                        href={attachment.url}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="flex items-center space-x-2 text-sm text-indigo-600 hover:text-indigo-500"
                      >
                        <svg className="h-4 w-4" fill="currentColor" viewBox="0 0 20 20">
                          <path fillRule="evenodd" d="M4 4a2 2 0 012-2h4.586A2 2 0 0112 2.586L15.414 6A2 2 0 0116 7.414V16a2 2 0 01-2 2H6a2 2 0 01-2-2V4z" clipRule="evenodd" />
                        </svg>
                        <span>{attachment.name}</span>
                      </a>
                    );
                  }
                })}
              </div>
            )}
          </div>
        ))}
      </div>

      {error && (
        <div className="mt-4 text-red-600 text-sm">
          {error}
        </div>
      )}
    </div>
  );
} 
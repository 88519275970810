export default function LoadingSpinner({ size = 'medium', color = 'indigo' }) {
  const sizeClasses = {
    small: 'w-4 h-4',
    medium: 'w-8 h-8',
    large: 'w-12 h-12'
  };

  const colorClasses = {
    indigo: 'border-indigo-500',
    gray: 'border-gray-500',
    white: 'border-white'
  };

  return (
    <div className="flex items-center justify-center">
      <div
        className={`
          ${sizeClasses[size]}
          border-2
          border-t-transparent
          rounded-full
          animate-spin
          ${colorClasses[color]}
        `}
      ></div>
    </div>
  );
} 